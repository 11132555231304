import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export interface SliderProps {
  id?: string;
  name?: string;
  defaultValue?: number;
  step?: number;
  min: number;
  max: number;
  className?: string;
  showTooltip?: boolean;
  aside?: string;
  formatTooltip?: (value: number) => string;
  minLabel?: (min: number) => string;
  maxLabel?: (max: number) => string;
  onChange: (value: number) => void;
}

export const Slider: React.FC<SliderProps> = ({
  min,
  max,
  defaultValue,
  onChange,
  ...rest
}) => {
  const bubbleRef = React.useRef<HTMLDivElement | null>(null);

  const getTooltipText = (value: number) => {
    let tooltipText: string = value.toString();
    if (rest.formatTooltip) tooltipText = rest.formatTooltip(value);
    return `<span>${tooltipText}${rest.aside}</span>`;
  };

  const setBubblePosition = (value: number) => {
    const newValue = Number(((value - min) * 100) / (max - min));
    const newPosition = 10 - newValue * 0.2;

    if (bubbleRef.current) {
      bubbleRef.current.innerHTML = getTooltipText(value);
      bubbleRef.current.style.left = `calc(${newValue}% + (${newPosition}px))`;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value));
    setBubblePosition(Number(event.target.value));
  };

  return (
    <div className={cn(styles.Slider, rest.className)}>
      {rest.showTooltip && (
        <div ref={bubbleRef} className={styles.Slider__bubble} />
      )}

      <input
        type="range"
        min={min}
        max={max}
        id={rest.id}
        name={rest.name}
        step={rest.step}
        className={styles.Slider__input}
        defaultValue={defaultValue}
        onChange={handleChange}
      />

      <div className={styles.Slider__labels}>
        <span>
          {rest.minLabel ? rest.minLabel(min) : `${min}${rest.aside}`}
        </span>
        <span>
          {rest.maxLabel ? rest.maxLabel(max) : `${max}${rest.aside}`}
        </span>
      </div>
    </div>
  );
};

Slider.defaultProps = {
  step: 1,
  min: 0,
  max: 100,
  aside: '',
  showTooltip: true,
};
