import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(12.82 0 0 12.66 -6.9 -6.359)"
      d="M39 23.77c0-.95-.378-1.837-1.03-2.491a3.931 3.931 0 0 0 1.013-3.023c-.186-2.012-1.999-3.59-4.13-3.59h-9.78c.485-1.47 1.26-4.167 1.26-6.333C26.333 4.9 23.415 2 21.583 2c-1.643 0-2.82.926-2.869.964a.792.792 0 0 0-.297.62v5.368l-4.562 9.879-.188.096v-.302a.792.792 0 0 0-.792-.792H4.958A3.962 3.962 0 0 0 1 21.792v12.666a3.962 3.962 0 0 0 3.958 3.959h4.75a3.967 3.967 0 0 0 3.726-2.618c1.317.678 3.09 1.034 4.191 1.034h14.533c1.725 0 3.234-1.162 3.588-2.764a3.574 3.574 0 0 0-.285-2.38 3.548 3.548 0 0 0 1.585-4.75A3.548 3.548 0 0 0 39 23.771Zm-3.34 1.955a.793.793 0 0 0-.453 1.363c.403.378.626.888.626 1.433 0 .999-.755 1.838-1.754 1.954a.793.793 0 0 0-.453 1.363c.521.49.73 1.18.573 1.889-.194.883-1.052 1.523-2.04 1.523H17.625c-1.286 0-3.442-.603-4.19-1.352a.792.792 0 0 0-1.352.56 2.378 2.378 0 0 1-2.375 2.375h-4.75a2.378 2.378 0 0 1-2.375-2.375V21.792a2.378 2.378 0 0 1 2.375-2.375h7.125v.791c0 .274.143.53.377.675a.81.81 0 0 0 .77.033l1.583-.792a.79.79 0 0 0 .364-.375l4.75-10.292A.806.806 0 0 0 20 9.125V4.012c.33-.182.9-.429 1.583-.429.868 0 3.167 2.157 3.167 4.75 0 2.787-1.517 6.804-1.531 6.844a.797.797 0 0 0 .087.731.796.796 0 0 0 .652.342h10.895c1.32 0 2.442.945 2.553 2.152.084.902-.35 1.76-1.128 2.24a.79.79 0 0 0 .054 1.38 1.96 1.96 0 0 1 1.085 1.749c0 .999-.756 1.838-1.756 1.954Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(16.42 0 0 12.95 -51.49 -14.423)"
      d="M12.875 19.417a.792.792 0 0 0-.792.791v14.25a.792.792 0 0 0 1.584 0v-14.25a.792.792 0 0 0-.792-.791Z"
    />
  </svg>
);

const Memo = memo(SvgThumbsUp);
export default Memo;
