import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(38)"
      d="M419.756 356.262c-.373-.285-38.156-28.901-38.156-95.837 0-66.307 36.644-94.7 38.156-95.838 2.67-1.963 4.244-5.056 4.244-8.331 0-56.642-44.74-90.264-46.644-91.668a10.779 10.779 0 0 0-8.437-1.882c-34.854 6.846-62.006 3.825-80.712-9.003-19.813-13.58-23.187-33.56-23.311-34.344-.498-3.377-2.65-6.318-5.756-7.843C258.178 11.047 235.436 0 212 0c-23.435 0-46.179 11.048-47.141 11.516a10.56 10.56 0 0 0-5.745 7.782c-.135.845-3.509 20.824-23.323 34.404-18.695 12.827-45.867 15.85-80.711 9.003-2.971-.59-6.045.092-8.437 1.882C44.74 65.99 0 99.612 0 156.254c0 3.275 1.573 6.368 4.244 8.331.373.285 38.156 28.901 38.156 95.838 0 66.306-36.644 94.7-38.156 95.837C1.573 358.223 0 361.316 0 364.591c0 2.34.528 57.68 46.643 91.668 2.37 1.739 5.496 2.197 8.457 1.699 2.93-.55 5.61-2.492 7.174-4.995.093-.152 10.176-15.453 54.325-15.453 46.624 0 86.187 57.293 86.59 57.872 1.967 2.889 5.27 4.618 8.81 4.618 3.54 0 6.843-1.73 8.81-4.618.404-.58 39.968-57.872 86.59-57.872 43.829 0 54.078 15.077 54.118 15.077 1.408 2.767 3.985 4.77 7.05 5.484 3.023.702 6.263.05 8.789-1.811C423.472 422.273 424 366.932 424 364.592c0-3.275-1.573-6.367-4.244-8.33zm-47.835 77.324c-9.503-7.64-28.456-16.909-64.521-16.909-43.59 0-79.47 36.369-95.4 55.606-15.931-19.237-51.81-55.606-95.4-55.606-36.066 0-55.02 9.269-64.522 16.909-24.326-22.412-29.491-53.073-30.589-64.497C33.27 358.215 63.6 324.023 63.6 260.423c0-63.896-30.62-98.107-42.276-108.82 1.966-35.237 25.714-59.561 34.667-67.527 38.653 6.756 69.698 2.178 92.368-13.58 19.99-13.907 27.65-32.766 30.372-42.247 6.739-2.736 20.206-7.416 33.27-7.416 13.064 0 26.531 4.68 33.271 7.416 2.723 9.481 10.383 28.341 30.372 42.247 22.67 15.759 53.715 20.327 92.367 13.581 8.953 7.966 32.7 32.29 34.667 67.528-11.656 10.712-42.276 44.923-42.276 108.819 0 63.6 30.33 97.79 42.11 108.666-1.095 11.423-6.26 42.084-30.587 64.496z"
    />
  </svg>
);

const Memo = memo(SvgBadge);
export default Memo;
