import React from 'react';
import styles from './index.module.scss';

interface LoadingProps {
  height?: string;
}

export const Loading = React.memo<LoadingProps>(({ height }) => (
  <div className={styles.Loading} style={{ height: height || 'auto' }}>
    <span className={styles.Spinner} />
  </div>
));
