import * as React from 'react';
import { SVGProps, memo } from 'react';
const PhoneOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    viewBox="0 -960 960 960"
    width="48"
    {...props}
  >
    <path
      style={{
        opacity: 1,
        fill: '#012059',
        stroke: '#012059',
        strokeWidth: 3.23161912,
        strokeLinecap: 'round',
        strokeMiterlimit: 4,
        fillOpacity: 1,
        strokeOpacity: 1,
      }}
      d="M795-120q-122 0-242.5-60T336-336q-96-96-156-216.5T120-795q0-19.286 12.857-32.143T165-840h140q13.611 0 24.306 9.5Q340-821 343-805l27 126q2 14-.5 25.5T359-634L259-533q56 93 125.5 162T542-254l95-98q10-11 23-15.5t26-1.5l119 26q15.312 3.375 25.156 15.188Q840-316 840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q0 39 12 85.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z"
    />
  </svg>
);
const Memo = memo(PhoneOutline);
export default Memo;
