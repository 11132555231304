import React from 'react';
import { Button, SvgIcon } from '@ui-elements';
import styles from './index.module.scss';
import cn from 'classnames';
import { colorPrimary } from '~/common/utils';

interface ModalProps {
  children?: JSX.Element;
  lockScroll?: boolean;
  closeClickModal?: boolean;
  closeModal: () => void;
  hideClose?: boolean;
  type?: string;
  modalContainerClass?: string;
  modalContentClass?: string;
  closeButtonColor?: string;
  modalWrapperClass?: string;
  modalCloseButtonClass?: string;
}

export const Modal: React.FC<ModalProps> = ({
  hideClose,
  closeModal,
  closeClickModal,
  lockScroll,
  children,
  type,
  modalContainerClass,
  modalContentClass,
  closeButtonColor,
  modalWrapperClass,
  modalCloseButtonClass,
}) => {
  React.useEffect(() => {
    if (lockScroll) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    }
  }, [lockScroll]);

  const handleCloseModal = (e: React.MouseEvent, close?: boolean) => {
    e.stopPropagation();

    if (close) {
      if (lockScroll) {
        const modals = document.getElementsByClassName(
          'modalContainerLockScroll'
        );
        if (modals.length === 1) {
          document.body.style.removeProperty('overflow');
          document.documentElement.style.removeProperty('overflow');
        }
      }

      closeModal();
    }
  };

  return (
    <div
      className={`${lockScroll ? 'modalContainerLockScroll' : ''} ${cn(
        styles.modalContainer,
        modalContainerClass
      )}`}
    >
      <div
        className={cn(styles.modalWrapper, modalWrapperClass)}
        onClick={(e) => handleCloseModal(e, closeClickModal)}
      >
        <div
          className={cn(
            { [styles.modalContent]: !type },
            { [styles.mediaModalContent]: type === 'media' },
            modalContentClass
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {!hideClose && (
            <Button
              type="text"
              className={cn(styles.closeButton, modalCloseButtonClass)}
              onClick={(e) => handleCloseModal(e, true)}
            >
              <SvgIcon
                type="close"
                size={2}
                color={closeButtonColor || `#${colorPrimary}`}
              />
            </Button>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  lockScroll: true,
  closeClickModal: true,
};
