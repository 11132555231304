import * as React from 'react';
import { SVGProps } from 'react';
const SvgBasket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 17 19"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M16.59 17.936 15.133 4.513a.564.564 0 0 0-.567-.494h-2.74v-.604C11.827 1.532 10.244 0 8.297 0c-1.946 0-3.53 1.532-3.53 3.415v.604H2.029a.564.564 0 0 0-.568.494L.003 17.936a.541.541 0 0 0 .144.427.58.58 0 0 0 .424.183h15.452a.58.58 0 0 0 .424-.183.54.54 0 0 0 .143-.427ZM5.908 3.415c0-1.274 1.071-2.311 2.389-2.311 1.317 0 2.389 1.037 2.389 2.31v.605H5.908v-.604ZM2.542 5.123 1.204 17.442H15.39l-1.337-12.32h-2.225V6.34a.562.562 0 0 1-.57.552.562.562 0 0 1-.571-.552V5.123H5.908v1.216a.562.562 0 0 1-.57.552.562.562 0 0 1-.571-.552V5.123H2.542Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = React.memo(SvgBasket);
export default Memo;
