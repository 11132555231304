import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 17 18"
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M0 6.954C0 3.169 3.074.086 6.847.086c3.773 0 6.847 3.083 6.847 6.868a6.85 6.85 0 0 1-1.535 4.33l4.634 4.647a.71.71 0 0 1-1.004 1l-4.633-4.648a6.796 6.796 0 0 1-4.31 1.54C3.075 13.822 0 10.738 0 6.953Zm12.277 0a5.428 5.428 0 0 0-5.43-5.447 5.428 5.428 0 0 0-5.43 5.447 5.428 5.428 0 0 0 5.43 5.447c3.007 0 5.43-2.43 5.43-5.447Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgSearch);
export default Memo;
