import * as React from 'react';
import { SVGProps } from 'react';
const SvgBullHorn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={props.color}
      d="M10.4211 13.997V5.78955C10.1957 5.81107 9.97295 5.84739 9.74629 5.85815H3.25658C2.89705 5.85815 2.60526 6.15944 2.60526 6.53067V7.2032H0.651316C0.291789 7.2032 0 7.50449 0 7.87572V11.9109C0 12.2821 0.291789 12.5834 0.651316 12.5834H2.60526V13.2559C2.60526 13.6271 2.89705 13.9284 3.25658 13.9284H3.65128L6.26957 21.1352C6.42979 21.567 6.78801 21.8804 7.2283 21.9718C7.31558 21.9907 7.40286 22.0001 7.48883 22.0001C7.83663 22.0001 8.1675 21.8575 8.40458 21.6019L9.96904 20C10.3689 19.5871 10.464 18.9428 10.2087 18.4438L8.02291 13.9284H9.7528C9.97686 13.9392 10.1983 13.9755 10.4211 13.997Z"
    />
    <path
      fill={props.color}
      d="M21.892 0.340256C21.8787 0.316353 21.8668 0.296669 21.8496 0.274173C21.7768 0.175752 21.6855 0.095609 21.573 0.0492105C21.5691 0.0478045 21.5664 0.0421805 21.5624 0.0407744C21.5492 0.0365564 21.5373 0.0435865 21.5241 0.0393684C21.4645 0.0210902 21.405 0 21.3388 0C21.2938 0 21.2541 0.0182782 21.2104 0.0281203C21.1853 0.0337444 21.1615 0.0351504 21.1363 0.0435865C21.0225 0.0829549 20.9246 0.150444 20.8465 0.243241C20.8412 0.248865 20.8333 0.250271 20.828 0.255895C18.765 2.91748 15.8881 4.6975 12.7373 5.34989V14.3357C15.8881 14.9881 18.765 16.7667 20.828 19.4283C20.8333 19.4353 20.8426 19.4382 20.8492 19.4466C20.8955 19.5014 20.9524 19.545 21.0133 19.5816C21.0331 19.5942 21.049 19.6125 21.0689 19.6223C21.1522 19.6603 21.2422 19.6842 21.3388 19.6842C21.4142 19.6842 21.4883 19.6702 21.5624 19.6434C21.5664 19.642 21.5691 19.6364 21.573 19.635C21.6855 19.5886 21.7768 19.5099 21.8496 19.41C21.8655 19.3875 21.8774 19.3679 21.892 19.344C21.9555 19.2357 22.0005 19.1162 22.0005 18.9812V0.703008C22.0005 0.56803 21.9555 0.448519 21.892 0.340256Z"
    />
  </svg>
);
const Memo = React.memo(SvgBullHorn);
export default Memo;
