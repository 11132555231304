import * as React from 'react';
import { SVGProps } from 'react';
const SvgCoin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 21"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M10 .435c-5.513 0-10 4.487-10 10 0 5.512 4.487 10 10 10 5.512 0 10-4.488 10-10 0-5.513-4.488-10-10-10Zm0 1.739a8.248 8.248 0 0 1 8.26 8.26A8.248 8.248 0 0 1 10 18.697a8.248 8.248 0 0 1-8.26-8.261A8.248 8.248 0 0 1 10 2.174Zm-.87 2.609a.87.87 0 1 1 1.74 0v.543c1.139.254 2.014.965 2.486 1.753.235.391.092.96-.299 1.195-.391.235-.96.093-1.196-.298-.282-.47-.967-1.02-1.861-1.02-.566 0-1.018.163-1.304.395a1.077 1.077 0 0 0-.435.91c.004.625.857.926 1.601 1.188.163.058.321.114.464.17.163.065.334.125.51.187.452.16.937.33 1.406.615.65.396 1.236 1.198 1.236 2.188 0 .909-.422 1.729-1.073 2.255-.438.354-.962.591-1.535.707v.516a.87.87 0 0 1-1.74 0v-.543c-1.139-.258-2.014-.98-2.486-1.767-.235-.391-.092-.96.299-1.195.391-.235.96-.093 1.196.298.282.47.967 1.033 1.861 1.033.566 0 1.018-.176 1.304-.408.287-.231.435-.488.435-.896-.004-.64-.86-.941-1.607-1.205-.16-.056-.317-.111-.458-.168-.163-.064-.334-.124-.51-.186-.452-.16-.937-.33-1.406-.615-.65-.396-1.236-1.184-1.236-2.174 0-.91.422-1.743 1.073-2.27A3.47 3.47 0 0 1 9.13 5.3v-.516Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = React.memo(SvgCoin);
export default Memo;
