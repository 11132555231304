import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgModelSedanHatch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(-13.16 0 0 13.17 493.46 138.055)"
      d="m36.766 9.451-.303-.358a.297.297 0 0 1-.083-.208l-.073-2.312a2.624 2.624 0 0 0-.715-1.736L32.584 1.78l.01-.028a.796.796 0 0 0-.147-.67.806.806 0 0 0-.578-.35L30.86.61h-.009C29.66.487 23.037-.522 19.415.355c-1.954.472-6.263 3.039-8.657 4.52l-.055.038c-.275 0-.834.01-1.072.028-3.953.302-6.254.887-7.263 1.208-.458.15-.917.717-1.054.896-.486.463-.78 1.472-.862 1.802l-.165.255a1.688 1.688 0 0 0-.257.991l.193 2.604-.138.189a.482.482 0 0 0 .046.604l.156.16c.275.293.642.482 1.027.52l3.53.358C5.404 16.009 6.788 17 8.33 17c1.705 0 3.181-1.18 3.612-2.878l12.976-.141c.376 1.755 1.889 3.019 3.64 3.019 1.77 0 3.292-1.283 3.65-3.067l2.954-.273c.293-.038.568-.17.77-.368l.725-.69c.22-.207.339-.5.339-.801v-1.614a.985.985 0 0 0-.23-.736Zm-8.19.821c1.55 0 2.815 1.302 2.815 2.897 0 1.595-1.266 2.897-2.815 2.897-1.55 0-2.815-1.302-2.815-2.897 0-1.595 1.265-2.897 2.815-2.897Zm-20.238 0c1.55 0 2.815 1.302 2.815 2.897 0 1.595-1.266 2.897-2.815 2.897-1.55 0-2.815-1.302-2.815-2.897 0-1.595 1.265-2.897 2.815-2.897Zm.009-.934c-2.054 0-3.732 1.727-3.732 3.84 0 .133.009.265.027.397l-3.218-.33a.705.705 0 0 1-.34-.123l.01-.01a.426.426 0 0 0 .073-.302l-.202-2.783a.64.64 0 0 1 .101-.396l.211-.321a.323.323 0 0 0 .055-.113l.018-.048c.101-.443.376-1.198.633-1.424l.055-.057.01-.01c.192-.245.494-.556.623-.603.99-.312 3.228-.887 7.06-1.18.266-.019 1.12-.019 1.128-.019a.474.474 0 0 0 .239-.066s.018-.01.055-.038c1.476-.924 6.464-3.99 8.49-4.482 3.394-.82 9.915.151 11.098.265l.935.113-.036.18c-.037.16.009.32.128.424l3.173 3.236c.275.293.43.68.45 1.095l.072 2.312c.01.292.12.575.303.783l.303.358a.2.2 0 0 1 .046.114v1.566h-.01v.094a.185.185 0 0 1-.055.123l-.724.689a.443.443 0 0 1-.257.122L32.3 13c-.092-2.029-1.743-3.66-3.732-3.66-2.018 0-3.677 1.66-3.733 3.726l-12.764.132v-.019c.009-2.113-1.67-3.84-3.723-3.84Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(-13.16 0 0 13.17 493.47 138.065)"
      d="M14.392 7h.008l17.918-.804c.268-.01.507-.213.622-.533.107-.31.069-.668-.1-.94l-1.106-1.646c-.33-.485-.814-.775-1.298-.785l-3.34-.29c-.208-.02-.393.174-.416.445a.546.546 0 0 0 .093.349c.069.097.16.155.26.164l3.357.3c.292.01.568.175.753.446l1.021 1.512-17.795.794c-.215.01-.376.232-.369.494.016.29.185.494.392.494Z"
    />
  </svg>
);

const Memo = memo(SvgModelSedanHatch);
export default Memo;
