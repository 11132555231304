import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgExpert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(12.83 0 0 12.82 -6.685 -5.84)"
      d="M22.845 21.78a.621.621 0 0 0-.207 1.067.648.648 0 0 0 .627.118c5.305-1.806 8.506-7.11 7.574-12.546C29.907 4.98 25.114 1 19.5 1 13.886 1 9.093 4.981 8.16 10.419c-.931 5.437 2.27 10.74 7.575 12.546a.642.642 0 0 0 .813-.387.625.625 0 0 0-.394-.798 10.247 10.247 0 0 1-3.044-1.663v-.683c-.002-1.957 1.224-3.712 3.084-4.413a5.798 5.798 0 0 0 6.605 0c1.86.701 3.087 2.456 3.084 4.413v.686a10.247 10.247 0 0 1-3.038 1.66Zm-7.819-11.372c0-2.425 2.002-4.391 4.47-4.391 2.47 0 4.471 1.966 4.471 4.39 0 2.426-2.001 4.392-4.47 4.392-2.468-.003-4.468-1.967-4.47-4.391Zm8.814 3.684a5.573 5.573 0 0 0-.38-7.782c-2.22-2.079-5.711-2.079-7.932 0a5.573 5.573 0 0 0-.38 7.782 5.974 5.974 0 0 0-3.295 4.847 9.908 9.908 0 0 1 .563-13.898c3.956-3.733 10.206-3.733 14.161 0a9.908 9.908 0 0 1 .563 13.898 5.972 5.972 0 0 0-3.3-4.847Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(12.83 0 0 12.82 -7.1 -6.01)"
      d="M14.468 38.752c.393.289.917.328 1.35.103L20 36.695l4.18 2.16c.434.225.958.185 1.353-.102.394-.287.59-.772.507-1.25l-.794-4.559 3.36-3.221c.354-.339.482-.848.332-1.312a1.283 1.283 0 0 0-1.038-.874l-4.666-.666-2.082-4.163A1.278 1.278 0 0 0 20 22a1.279 1.279 0 0 0-1.15.706l-2.094 4.165-4.655.666c-.486.07-.889.409-1.04.873-.15.465-.02.974.333 1.313l3.362 3.221-.795 4.559c-.085.478.112.963.507 1.25Zm-2.185-9.95 4.989-.714a.642.642 0 0 0 .483-.346l2.244-4.464 2.235 4.463a.642.642 0 0 0 .483.347l4.999.714-3.608 3.457a.637.637 0 0 0-.187.57l.853 4.894-4.477-2.315a.646.646 0 0 0-.593 0l-4.478 2.315.853-4.893a.637.637 0 0 0-.187-.57l-3.609-3.458Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(-6.82 -6.83) scale(12.79)"
      d="m3.952 29.02-.613 3.617c-.06.337.03.684.246.947.215.263.533.415.868.416.184 0 .364-.046.527-.132l3.237-1.72 3.236 1.724a.616.616 0 0 0 .627-.028c.189-.123.3-.34.29-.568a.641.641 0 0 0-.338-.54L8.507 30.86a.616.616 0 0 0-.58 0l-3.31 1.76.627-3.713a.648.648 0 0 0-.182-.57l-2.664-2.625 3.686-.54c.204-.03.38-.16.472-.348l1.661-3.39 1.655 3.389a.628.628 0 0 0 .473.348l3.939.576a.63.63 0 0 0 .71-.543.638.638 0 0 0-.533-.724l-3.613-.528-1.616-3.312A1.127 1.127 0 0 0 8.215 20H8.21c-.426 0-.816.246-1.004.636l-1.624 3.316-3.603.527a1.135 1.135 0 0 0-.925.788c-.134.422-.018.886.298 1.191l2.599 2.562Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(-5.78 -6.83) scale(12.79)"
      d="M38.943 25.266a1.124 1.124 0 0 0-.917-.785l-3.608-.528-1.622-3.313a1.117 1.117 0 0 0-1.004-.64h-.003a1.128 1.128 0 0 0-1.018.64l-1.617 3.31-3.615.527a.63.63 0 0 0-.494.396.65.65 0 0 0 .089.633c.138.18.36.27.582.238l3.941-.576a.628.628 0 0 0 .474-.348l1.651-3.383 1.659 3.388a.628.628 0 0 0 .472.347l3.686.54-2.665 2.624a.648.648 0 0 0-.183.57l.631 3.712-3.312-1.761a.617.617 0 0 0-.58 0l-3.526 1.875a.641.641 0 0 0-.337.54c-.01.228.1.445.29.567.189.123.428.134.627.028l3.238-1.72 3.239 1.723c.383.203.846.166 1.194-.094.348-.261.521-.7.446-1.134l-.611-3.622 2.602-2.56c.314-.307.428-.772.29-1.194Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(12.8 0 0 12.75 -4.8 -5.25)"
      d="M16.665 31a.682.682 0 0 0 .096-.007l2.507-.36a.666.666 0 0 0 .5-.36l1.16-2.309a.664.664 0 0 0-.296-.893.669.669 0 0 0-.896.295l-1 2-2.163.31a.665.665 0 0 0 .094 1.32l-.002.004Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(12.83 0 0 12.82 -6.685 -5.84)"
      d="M22.845 21.78a.621.621 0 0 0-.207 1.067.648.648 0 0 0 .627.118c5.305-1.806 8.506-7.11 7.574-12.546C29.907 4.98 25.114 1 19.5 1 13.886 1 9.093 4.981 8.16 10.419c-.931 5.437 2.27 10.74 7.575 12.546a.642.642 0 0 0 .813-.387.625.625 0 0 0-.394-.798 10.247 10.247 0 0 1-3.044-1.663v-.683c-.002-1.957 1.224-3.712 3.084-4.413a5.798 5.798 0 0 0 6.605 0c1.86.701 3.087 2.456 3.084 4.413v.686a10.247 10.247 0 0 1-3.038 1.66Zm-7.819-11.372c0-2.425 2.002-4.391 4.47-4.391 2.47 0 4.471 1.966 4.471 4.39 0 2.426-2.001 4.392-4.47 4.392-2.468-.003-4.468-1.967-4.47-4.391Zm8.814 3.684a5.573 5.573 0 0 0-.38-7.782c-2.22-2.079-5.711-2.079-7.932 0a5.573 5.573 0 0 0-.38 7.782 5.974 5.974 0 0 0-3.295 4.847 9.908 9.908 0 0 1 .563-13.898c3.956-3.733 10.206-3.733 14.161 0a9.908 9.908 0 0 1 .563 13.898 5.972 5.972 0 0 0-3.3-4.847Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(12.83 0 0 12.82 -7.1 -6.01)"
      d="M14.468 38.752c.393.289.917.328 1.35.103L20 36.695l4.18 2.16c.434.225.958.185 1.353-.102.394-.287.59-.772.507-1.25l-.794-4.559 3.36-3.221c.354-.339.482-.848.332-1.312a1.283 1.283 0 0 0-1.038-.874l-4.666-.666-2.082-4.163A1.278 1.278 0 0 0 20 22a1.279 1.279 0 0 0-1.15.706l-2.094 4.165-4.655.666c-.486.07-.889.409-1.04.873-.15.465-.02.974.333 1.313l3.362 3.221-.795 4.559c-.085.478.112.963.507 1.25Zm-2.185-9.95 4.989-.714a.642.642 0 0 0 .483-.346l2.244-4.464 2.235 4.463a.642.642 0 0 0 .483.347l4.999.714-3.608 3.457a.637.637 0 0 0-.187.57l.853 4.894-4.477-2.315a.646.646 0 0 0-.593 0l-4.478 2.315.853-4.893a.637.637 0 0 0-.187-.57l-3.609-3.458Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(-6.82 -6.83) scale(12.79)"
      d="m3.952 29.02-.613 3.617c-.06.337.03.684.246.947.215.263.533.415.868.416.184 0 .364-.046.527-.132l3.237-1.72 3.236 1.724a.616.616 0 0 0 .627-.028c.189-.123.3-.34.29-.568a.641.641 0 0 0-.338-.54L8.507 30.86a.616.616 0 0 0-.58 0l-3.31 1.76.627-3.713a.648.648 0 0 0-.182-.57l-2.664-2.625 3.686-.54c.204-.03.38-.16.472-.348l1.661-3.39 1.655 3.389a.628.628 0 0 0 .473.348l3.939.576a.63.63 0 0 0 .71-.543.638.638 0 0 0-.533-.724l-3.613-.528-1.616-3.312A1.127 1.127 0 0 0 8.215 20H8.21c-.426 0-.816.246-1.004.636l-1.624 3.316-3.603.527a1.135 1.135 0 0 0-.925.788c-.134.422-.018.886.298 1.191l2.599 2.562Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(-5.78 -6.83) scale(12.79)"
      d="M38.943 25.266a1.124 1.124 0 0 0-.917-.785l-3.608-.528-1.622-3.313a1.117 1.117 0 0 0-1.004-.64h-.003a1.128 1.128 0 0 0-1.018.64l-1.617 3.31-3.615.527a.63.63 0 0 0-.494.396.65.65 0 0 0 .089.633c.138.18.36.27.582.238l3.941-.576a.628.628 0 0 0 .474-.348l1.651-3.383 1.659 3.388a.628.628 0 0 0 .472.347l3.686.54-2.665 2.624a.648.648 0 0 0-.183.57l.631 3.712-3.312-1.761a.617.617 0 0 0-.58 0l-3.526 1.875a.641.641 0 0 0-.337.54c-.01.228.1.445.29.567.189.123.428.134.627.028l3.238-1.72 3.239 1.723c.383.203.846.166 1.194-.094.348-.261.521-.7.446-1.134l-.611-3.622 2.602-2.56c.314-.307.428-.772.29-1.194Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(12.8 0 0 12.75 -4.8 -5.25)"
      d="M16.665 31a.682.682 0 0 0 .096-.007l2.507-.36a.666.666 0 0 0 .5-.36l1.16-2.309a.664.664 0 0 0-.296-.893.669.669 0 0 0-.896.295l-1 2-2.163.31a.665.665 0 0 0 .094 1.32l-.002.004Z"
    />
  </svg>
);

const Memo = memo(SvgExpert);
export default Memo;
