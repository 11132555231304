import React from 'react';
import cn from 'classnames';
import { SvgIcon } from '../svg-icon';
import { Breadcrumb } from '~/common/models';
import { useIsMobile } from '~/common/hooks/use-is-mobile';
import styles from './index.module.scss';

interface BreadcrumbsProps {
  className?: string;
  breadcrumbs: Breadcrumb[] | undefined;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  className,
}) => {
  const { containerRef, isMobile } = useIsMobile();
  const isBreadcrumb = breadcrumbs ? breadcrumbs.length <= 1 : true;
  const getBreadcrumName = (name?: string) => (name === '/' ? '' : name);

  return (
    <div ref={containerRef} className={cn(styles.breadcrumbs, className)}>
      <ul>
        {breadcrumbs?.map((breadcrumb, index) => {
          const isLastBreadcrumb = index === breadcrumbs.length - 1;
          return (
            !breadcrumb.ctaHidden && (
              <li
                key={index}
                className={cn({
                  [styles.hideInMobile]:
                    isMobile() && breadcrumb.ctaHideInMobile,
                })}
              >
                {!!breadcrumb.ctaIcon && breadcrumb.ctaIcon !== 'none' && (
                  <div className={styles.ctaIcon}>
                    <SvgIcon
                      className={styles.icon}
                      type={breadcrumb.ctaIcon}
                      color="000"
                      fill="000"
                      size={1}
                    />
                  </div>
                )}

                {!isLastBreadcrumb ? (
                  <div className={styles.breadcrumbItem}>
                    <a
                      href={breadcrumb.ctaUrl?.url}
                      onClick={(event) => {
                        if (typeof breadcrumb.onClick !== 'undefined') {
                          event.preventDefault();
                          breadcrumb.onClick(breadcrumb.ctaUrl?.url || '');
                        }
                      }}
                    >
                      <span className={styles.displayText}>
                        {getBreadcrumName(breadcrumb.ctaUrl?.name)}
                      </span>
                    </a>
                  </div>
                ) : (
                  <div className={styles.breadcrumbItem}>
                    <span
                      className={styles.activeText}
                      style={{ color: isBreadcrumb ? '#323334' : '#4d75f5;' }}
                    >
                      {getBreadcrumName(breadcrumb.ctaUrl?.name)}
                    </span>
                  </div>
                )}
                {!isLastBreadcrumb && (
                  <div className={styles.divider}>
                    <SvgIcon
                      className={styles.icon}
                      type="chevronRight"
                      color="#9B9B9B"
                      size={0.7}
                    />
                  </div>
                )}
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
};
