import React from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

interface TabMenuProps {
  tabs: string[];
  selectedTabIndex: number;
  onTabClick: (index: number) => void;
}

const TabMenu: React.FC<TabMenuProps> = ({
  tabs,
  selectedTabIndex,
  onTabClick,
}) => {
  return (
    <div className={styles.modelArticleTab}>
      <ul>
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={cn(styles.tab, {
              [styles.tabActive]: index === selectedTabIndex,
            })}
            onClick={() => onTabClick(index)}
          >
            {tab}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabMenu;
