import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface RadioButtonOption {
  label: string;
  value: string | number;
}

interface RadioButtonProps {
  name: string;
  options: RadioButtonOption[];
  selectedValue: string;
  onChange: (value: string) => void;
  className?: string;
  error?: string;
  inline?: boolean;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  name,
  options,
  selectedValue,
  onChange,
  className,
  error,
  inline,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div
      className={cn(className, styles.RadioButton, {
        [styles.RadioButtonInline]: inline,
      })}
    >
      {options.map((option) => (
        <label key={option.value}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={handleChange}
          />
          {option.label}
        </label>
      ))}
      {error && <span className={cn(styles.TextFieldErrorMsg)}>{error}</span>}
    </div>
  );
};
