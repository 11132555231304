import * as React from 'react';
import { SVGProps } from 'react';
const SvgDrive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={props.color}
      d="M16 31.008q-4.064 0-7.584-2.048-3.392-1.984-5.376-5.376Q.992 20.064.992 16T3.04 8.416Q5.024 5.024 8.416 3.04 11.936.992 16 .992t7.584 2.048q3.392 1.984 5.376 5.376 2.048 3.52 2.048 7.584t-2.048 7.584q-1.984 3.392-5.376 5.376-3.52 2.048-7.584 2.048zm0-28.32q-3.616 0-6.752 1.824-2.976 1.792-4.736 4.8-1.824 3.104-1.824 6.704t1.824 6.736q1.792 2.976 4.8 4.736 3.104 1.824 6.704 1.824t6.736-1.824q2.976-1.792 4.736-4.8 1.824-3.104 1.824-6.72t-1.824-6.72Q25.76 6.24 22.752 4.512 19.648 2.688 16 2.688zm10.816 15.296q-.704 3.2-2.944 5.536t-5.376 3.072H18.4q-.192 0-.288-.096h-.096q-.032 0-.096-.048t-.128-.048v-.064q-.032-.032-.096-.032l-.096-.096v-.32q0-.128-.048-.432t-.048-.464q0-2.112 1.056-3.936t2.848-2.88q1.824-1.088 4-1.088h1.056q.032.032.032.096h.064q.032.032.032.128v.032q.032.064.096.064v.192q.128.288.128.384zm-7.712 6.72q2.048-.768 3.584-2.352t2.208-3.664q-1.568.096-2.88.928t-2.096 2.176-.816 2.912zM26.816 13.6v.192q0 .064-.064.128l-.064.064q0 .064-.064.128l-.128.096q-.032 0-.08.048t-.112.048h-.608q-1.856-.608-4.432-.912t-5.456-.304-5.456.304-4.464.912h-.8l-.192-.192v-.8q.544-2.528 2.144-4.528t3.92-3.136 4.912-1.136 4.896 1.136 3.92 3.136 2.224 4.528v.096h-.128l.016.064.016.128zM15.808 6.208q-1.984 0-3.76.768T8.928 9.12t-2.016 3.168q3.872-.896 8.896-.896t8.896.896Q24 10.496 22.656 9.12t-3.104-2.144-3.744-.768zm-10.4 10.88q.096-.096.288-.096h.512q2.112 0 3.936 1.056t2.88 2.848q1.088 1.856 1.088 4 0 .608-.096.896v.32q0 .064-.064.096h-.064v.032q-.032.064-.08.064t-.096.048-.112.048h-.096l-.288.096h-.224q-3.072-.736-5.296-3.072T4.8 17.984q-.064-.224.112-.48t.496-.416zm7.104 7.616q-.032-1.568-.816-2.88t-2.064-2.128-2.816-.912q.608 2.016 2.128 3.6t3.568 2.32zm.384-7.904q0-1.216.864-2.064t2.048-.848 2.032.848.848 2.064-.848 2.064-2.032.848-2.048-.864-.864-2.048zm2.912 2.112q.8 0 1.392-.592t.592-1.424-.576-1.408-1.408-.576-1.424.592-.592 1.408.592 1.408 1.424.592z"
    />
  </svg>
);
const Memo = React.memo(SvgDrive);
export default Memo;
