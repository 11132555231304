import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgModelSuv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: props.color,
        strokeWidth: 0.3,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: 'none',
        fillRule: 'evenodd',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.97 0 0 13.99 5.525 117.1)"
      d="M4.58.02C3.298.084 3.152.23 3.09.292c-.089.085-.12.232-.142.34a18.604 18.604 0 0 0-.14.654 34.577 34.577 0 0 0-.273 1.547 17.2 17.2 0 0 0-.177 1.548 1.565 1.565 0 0 0-.447-.06c-.28 0-.585.005-.731.044-.118.032-.405.123-.535.272C.475 4.838 0 5.929 0 8.27c0 1.162.068 2.711.391 3.494.117.293.316.507.52.556.018.005.034.01.05.013v1.307c0 .239.135.452.328.517l.852.29a.229.229 0 0 0 .078.165l.627.516a.297.297 0 0 0 .196.071c.041 0 .086-.007.136-.022l.584-.172c.136-.04.237-.174.259-.342.268-2.109 7.973-1.362 7.965.751 0 .079-.002.156-.006.232a.376.376 0 0 0 .075.25.26.26 0 0 0 .203.104h13.545a.257.257 0 0 0 .2-.103.37.37 0 0 0 .075-.246 4.814 4.814 0 0 1-.006-.204l7.898-1.125c.044.23.212.39.407.39a.355.355 0 0 0 .169-.043l.175-.093c.642-.342-.033-.431.033-.519.184-.238.177-.528.172-.761l-.002-.117v-.907c0-.204-.146-.354-.217-.425-.22-.219.422-.38.23-.51a4.363 4.363 0 0 0-.239-.146.804.804 0 0 0-.065-.034l-.034-.017a1.972 1.972 0 0 0-.224-.076v-.398c0-.311-.106-.572-.118-.6-.017-.038-.344-.741-.876-1.172a.82.82 0 0 0-.095-.406c-.005-.225-.03-.81-.177-1.02l-.04-.061a.769.769 0 0 0-.45-.369 66.282 66.282 0 0 1-1.324-.506c-.063-.023-.141-.058-.231-.097-.232-.102-.521-.228-.773-.283-.295-.065-1.37-.275-3.012-.343-.043-.09-.122-.24-.307-.247a7.367 7.367 0 0 0-.423 0c-.067-.067-.155-.078-.236-.078h-.336a.167.167 0 0 0-.12.054l-.007.007h-.582c-.076 0-.14.054-.168.132l.704-.01a.237.237 0 0 0-.17-.083.209.209 0 0 0-.152.071l-.261-.018a1.584 1.584 0 0 1-.206-.076l-.02-.012c-.062-.037-.127-.077-.21-.077a.254.254 0 0 0-.047.005.207.207 0 0 0-.103.039 1.975 1.975 0 0 1-.364-.005 77.98 77.98 0 0 1-.661-.795c-.152-.26-1.782-3.06-2.031-3.448-.24-.378-.585-.779-1.311-.882C19.661.299 14.834.148 14.7.14c-.153-.005-3.714-.134-4.471-.134l-.81-.002A834.68 834.68 0 0 0 6.973 0C5.63 0 4.847.007 4.58.02Z"
      strokeLinecap="round"
    />
    <path
      style={{
        stroke: props.color,
        strokeWidth: 0.3,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: '#d7d7d7',
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.97 0 0 13.99 47.475 256.995)"
      d="M0 4.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0z"
      strokeLinecap="round"
    />
    <path
      style={{
        stroke: props.color,
        strokeWidth: 0.3,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: '#d7d7d7',
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.97 0 0 13.99 326.795 256.995)"
      d="M0 4.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0z"
      strokeLinecap="round"
    />
    <path
      style={{
        stroke: '#000',
        strokeWidth: 0,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(-13.97 0 0 13.99 410.665 117.095)"
      d="M5.392 6H5.4l17.918-.804c.268-.01.507-.213.622-.533.107-.31.069-.668-.1-.94l-1.106-1.646c-.33-.485-.814-.775-1.298-.785l-3.34-.29c-.208-.02-.393.174-.416.445a.546.546 0 0 0 .093.349c.069.097.16.155.26.164l3.357.3c.292.01.568.175.753.446l1.021 1.512-17.795.794c-.215.01-.376.232-.369.494.016.29.185.494.392.494Z"
    />
  </svg>
);

const Memo = memo(SvgModelSuv);
export default Memo;
