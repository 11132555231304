export const replaceRegex = (
  str: string,
  regex = /\s+/g,
  replaceWith = '-'
): string => {
  return str.replace(regex, replaceWith).toLowerCase();
};

export const firstLetterToUpper = (str?: string) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};
