import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgTap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(14.72 0 0 14.71 73.36 7.28)"
      d="M21.417 13.373h-.045a2.446 2.446 0 0 0-1.661.64c-.37-1.035-1.308-1.78-2.406-1.78a2.48 2.48 0 0 0-1.784.778c-.429-.88-1.29-1.478-2.279-1.478h-.048a2.32 2.32 0 0 0-1.467.496V5.751C11.727 4.234 10.562 3 9.145 3 7.729 3 6.57 4.233 6.57 5.75l-.012 12.455-.695-.856a3.492 3.492 0 0 0-2.479-1.32 3.422 3.422 0 0 0-2.584.928l-.605.508a.55.55 0 0 0-.134.671l5.881 11.462C7.018 31.693 9.064 33 11.282 33h6.629v-.006c3.36 0 6.085-2.957 6.089-6.587.001-1.624-.005-2.835-.004-3.816.003-2.626 0-3.594-.006-6.486-.003-1.514-1.16-2.732-2.573-2.732Zm1.496 9.214-.005 3.821c-.002 3.03-2.259 5.501-5.029 5.501h-6.596c-1.816 0-3.497-1.082-4.386-2.814L1.215 18.026l.28-.237a2.368 2.368 0 0 1 1.813-.672 2.43 2.43 0 0 1 1.722.924l1.646 2.031a.533.533 0 0 0 .596.167.545.545 0 0 0 .357-.513l.01-13.976c0-.915.683-1.66 1.506-1.66.824 0 1.506.745 1.506 1.661v8.427l-.001.089.001.038v3.603c0 .301.241.545.538.545a.542.542 0 0 0 .538-.545v-3.711c0-.883.665-1.573 1.467-1.573h.048c.824 0 1.51.728 1.51 1.643v3.297c0 .301.24.546.537.546a.542.542 0 0 0 .538-.546v-2.586c0-.916.653-1.661 1.481-1.661.824 0 1.476.744 1.476 1.66v2.441c0 .301.241.545.538.545a.542.542 0 0 0 .538-.545v-1.321c0-.916.688-1.633 1.512-1.633h.045c.822 0 1.492.729 1.494 1.642.006 2.89.005 3.858.002 6.481Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(14.72 0 0 14.71 73.36 7.34)"
      d="M4.789 6c.305 0 .553-.271.553-.606C5.351 3.08 7.065 1.21 9.176 1.21c2.112 0 3.826 1.87 3.835 4.184 0 .335.248.606.553.606.306 0 .554-.271.554-.606C14.108 2.41 11.898 0 9.176 0c-2.721 0-4.93 2.411-4.94 5.394 0 .335.247.606.553.606Z"
    />
  </svg>
);

const Memo = memo(SvgTap);
export default Memo;
