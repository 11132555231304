import * as React from 'react';
import { SVGProps } from 'react';
const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 19"
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M9.62 18.785 10 19l.38-.215c2.153-1.221 5.51-4.094 6.907-5.91C20.265 9 20.867 4.398 18.718 1.931 17.684.745 16.233.06 14.632.004 13.002-.053 11.385.55 10 1.718 8.615.551 6.993-.053 5.37.004 3.767.06 2.316.745 1.282 1.932-.866 4.4-.265 9.001 2.713 12.875c1.397 1.816 4.754 4.689 6.908 5.91Zm-4.198-17.2.157-.002c1.361 0 2.732.623 3.875 1.765l.546.546.546-.546c1.187-1.186 2.617-1.813 4.032-1.762 1.17.04 2.226.536 2.974 1.395 1.624 1.865.983 5.7-1.492 8.919-1.195 1.554-4.076 4.05-6.06 5.277-1.984-1.228-4.865-3.723-6.06-5.277C1.465 8.68.824 4.846 2.448 2.981c.748-.859 1.804-1.354 2.974-1.395Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = React.memo(SvgHeart);
export default Memo;
