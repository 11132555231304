import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgHorn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(25.4 0 0 25.42 12.642 29.253)"
      d="M8.849 11.05V4.57c-.191.018-.38.046-.573.055h-5.51a.543.543 0 0 0-.554.53v.532H.553a.543.543 0 0 0-.553.53v3.186c0 .293.248.531.553.531h1.66v.531c0 .293.247.531.552.531H3.1l2.224 5.69c.136.34.44.588.814.66a1.096 1.096 0 0 0 .999-.292l1.328-1.265c.34-.326.42-.834.204-1.228l-1.856-3.565h1.469c.19.009.378.037.567.054Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(25.4 0 0 25.42 12.61 29.247)"
      d="M18.589.269a.554.554 0 0 0-.27-.23c-.004-.001-.006-.006-.01-.007-.011-.003-.021.002-.032 0C18.226.016 18.175 0 18.119 0c-.038 0-.072.014-.109.022-.021.005-.041.006-.063.012a.558.558 0 0 0-.246.158c-.004.004-.011.006-.015.01-1.752 2.101-4.195 3.507-6.87 4.022v7.094c2.675.515 5.118 1.919 6.87 4.02.004.006.012.008.018.015.039.043.087.077.139.106.017.01.03.025.047.032a.584.584 0 0 0 .42.017c.003-.001.005-.006.008-.007a.546.546 0 0 0 .235-.177c.014-.018.024-.034.036-.052a.533.533 0 0 0 .092-.287V.555c0-.107-.038-.2-.092-.286Z"
    />
  </svg>
);

const Memo = memo(SvgHorn);
export default Memo;
