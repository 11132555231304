import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { IconEnum } from '../svg-icon';
import ReactDOMServer from 'react-dom/server';

export enum ButtonTypeEnum {
  primary = 'primary',
  secondary = 'secondary',
  bordered = 'bordered',
  borderedSecondary = 'borderedSecondary',
  text = 'text',
}

interface Props {
  text?: string;
  block?: boolean;
  loading?: boolean;
  type?: keyof typeof ButtonTypeEnum;
  rounded?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
  buttonWidth?:
    | 'fit-text'
    | 'full-width'
    | 'full-desktop-only'
    | 'full-mobile-and-tablet'
    | 'full-mobile-only';
  reloads?: boolean;
  icon?: keyof typeof IconEnum;
}

interface NativeProps {
  href?: string;
  disabled?: boolean;
  nativeType?: 'button' | 'submit' | 'reset';
}

export type ButtonElement = HTMLButtonElement & HTMLAnchorElement;

export type ButtonProps = React.HTMLProps<ButtonElement> & NativeProps & Props;

export const Button: React.FC<ButtonProps> = ({
  type,
  text,
  block,
  nativeType,
  buttonSize,
  buttonWidth,
  rounded,
  loading,
  className,
  reloads,
  icon,
  children,
  ...rest
}: ButtonProps) => {
  const ctaClass = cn(
    className,
    styles.Button,
    styles[`Button__${type}`],
    styles[`Button__${buttonSize}`],
    {
      [styles.Button__block]: block,
      [styles.Button__loading]: loading,
      [styles.Button__rounded]: rounded,
      [styles.Button__icon]: icon,
      [styles[`Button__${buttonWidth}`]]: !!buttonWidth,
    }
  );

  const innerHtml = () => {
    const htmlString = ReactDOMServer.renderToStaticMarkup(
      <>
        {loading && <span className="spinner" />}
        <span
          className={styles.Text}
          dangerouslySetInnerHTML={{
            __html: text as string,
          }}
        />
      </>
    );

    return !children
      ? {
          dangerouslySetInnerHTML: {
            __html: htmlString,
          },
        }
      : {};
  };

  const link = () => (
    <a {...rest} {...innerHtml()} className={ctaClass}>
      {children && (
        <span className="flex items-center justify-center">{children}</span>
      )}
    </a>
  );

  const button = () => (
    <button
      {...rest}
      {...innerHtml()}
      type={nativeType}
      className={ctaClass}
      disabled={!loading ? rest.disabled : true}
    >
      {children && (
        <>
          <span className="spinner" />
          <span className="flex items-center justify-center">{children}</span>
        </>
      )}
    </button>
  );

  return rest.href ? link() : button();
};

Button.defaultProps = {
  type: 'primary',
  nativeType: 'button',
  buttonSize: 'medium',
  buttonWidth: 'fit-text',
  rounded: true,
};
