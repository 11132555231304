import React from 'react';
import ChevronRight from './svgs/ChevronRight';
import ChevronLeft from './svgs/ChevronLeft';
import ChevronUp from './svgs/ChevronUp';
import ChevronDown from './svgs/ChevronDown';
import Close from './svgs/Close';
import Horn from './svgs/Horn';
import Home from './svgs/Home';
import Path from './svgs/Path';
import Leaf from './svgs/Leaf';
import Seat from './svgs/Seat';
import TireRim from './svgs/TireRim';
import Eye from './svgs/Eye';
import EyeClosed from './svgs/EyeClosed';
import Star from './svgs/Star';
import Badge from './svgs/Badge';
import Tap from './svgs/Tap';
import Download from './svgs/Download';
import Expert from './svgs/Expert';
import Mechanic from './svgs/Mechanic';
import ThumbsUp from './svgs/ThumbsUp';
import Check from './svgs/Check';
import Location from './svgs/Location';
import Play from './svgs/Play';
import PlusCircle from './svgs/PlusCircle';
import Pencil from './svgs/Pencil';
import ModelHybrid from './svgs/ModelHybrid';
import ModelPerformance from './svgs/ModelPerformance';
import ModelSedanHatch from './svgs/ModelSedanHatch';
import ModelSuv from './svgs/ModelSuv';
import SadFace from './svgs/SadFace';
import Search from './svgs/Search';
import CircularArrows from './svgs/CircularArrows';
import Minus from './svgs/Minus';
import MinusCircle from './svgs/MinusCircle';
import PauseCircle from './svgs/PauseCircle';
import PlayCircle from './svgs/PlayCircle';
import Plus from './svgs/Plus';
import Envelope from './svgs/Envelope';
import EnvelopeSolid from './svgs/EnvelopeSolid';
import Phone from './svgs/Phone';
import Mobile from './svgs/Mobile';
import PhoneOutline from './svgs/PhoneOutline';
import CompareCars from './svgs/CompareCars';
import Basket from './svgs/Basket';
import Car from './svgs/Car';
import User from './svgs/User';
import Coin from './svgs/Coin';
import HornSpeaker from './svgs/HornSpeaker';
import PinLocation from './svgs/PinLocation';
import Key from './svgs/Key';
import Heart from './svgs/Heart';
import Menu from './svgs/Menu';
import BullHorn from './svgs/BullHorn';
import Tools from './svgs/Tools';
import Clock from './svgs/Clock';
import Wrench from './svgs/Wrench';
import Cursor from './svgs/Cursor';
import Info from './svgs/Info';
import TradeIn from './svgs/TradeIn';
import Drive from './svgs/Drive';
import FinanceQuote from './svgs/FinanceQuote';
import PDFIcon from './svgs/PDFIcon';

export enum IconEnum {
  none = 'none',
  chevronRight = 'chevronRight',
  chevronLeft = 'chevronLeft',
  chevronUp = 'chevronUp',
  chevronDown = 'chevronDown',
  circularArrows = 'circularArrows',
  close = 'close',
  horn = 'horn',
  home = 'home',
  path = 'path',
  leaf = 'leaf',
  seat = 'seat',
  tireRim = 'tireRim',
  eye = 'eye',
  eyeClosed = 'eyeClosed',
  star = 'star',
  badge = 'badge',
  tap = 'tap',
  download = 'download',
  expert = 'expert',
  mechanic = 'mechanic',
  thumbsUp = 'thumbsUp',
  check = 'check',
  location = 'location',
  minus = 'minus',
  minusCircle = 'minusCircle',
  pauseCircle = 'pauseCircle',
  play = 'play',
  playCircle = 'playCircle',
  plus = 'plus',
  plusCircle = 'plusCircle',
  pencil = 'pencil',
  modelHybrid = 'modelHybrid',
  modelPerformance = 'modelPerformance',
  modelSedanHatch = 'modelSedanHatch',
  modelSuv = 'modelSuv',
  sadFace = 'sadFace',
  search = 'search',
  envelope = 'envelope',
  envelopeSolid = 'envelopeSolid',
  phone = 'phone',
  mobile = 'mobile',
  phoneOutline = 'phoneOutline',
  compareCars = 'compareCars',
  basket = 'basket',
  car = 'car',
  user = 'user',
  coin = 'coin',
  hornSpeaker = 'hornSpeaker',
  pinLocation = 'pinLocation',
  key = 'key',
  heart = 'heart',
  menu = 'menu',
  tools = 'tools',
  bullHorn = 'bullHorn',
  clock = 'clock',
  wrench = 'wrench',
  cursor = 'cursor',
  info = 'info',
  tradeIn = 'tradeIn',
  drive = 'drive',
  financeQuote = 'financeQuote',
  pdfIcon = 'pdfIcon',
}

const getIconComponent = (type: keyof typeof IconEnum) => {
  if (type === IconEnum.chevronRight) return ChevronRight;
  if (type === IconEnum.chevronLeft) return ChevronLeft;
  if (type === IconEnum.chevronUp) return ChevronUp;
  if (type === IconEnum.chevronDown) return ChevronDown;
  if (type === IconEnum.circularArrows) return CircularArrows;
  if (type === IconEnum.close) return Close;
  if (type === IconEnum.horn) return Horn;
  if (type === IconEnum.home) return Home;
  if (type === IconEnum.path) return Path;
  if (type === IconEnum.leaf) return Leaf;
  if (type === IconEnum.seat) return Seat;
  if (type === IconEnum.tireRim) return TireRim;
  if (type === IconEnum.eye) return Eye;
  if (type === IconEnum.eyeClosed) return EyeClosed;
  if (type === IconEnum.star) return Star;
  if (type === IconEnum.badge) return Badge;
  if (type === IconEnum.tap) return Tap;
  if (type === IconEnum.download) return Download;
  if (type === IconEnum.expert) return Expert;
  if (type === IconEnum.mechanic) return Mechanic;
  if (type === IconEnum.thumbsUp) return ThumbsUp;
  if (type === IconEnum.check) return Check;
  if (type === IconEnum.location) return Location;
  if (type === IconEnum.pauseCircle) return PauseCircle;
  if (type === IconEnum.play) return Play;
  if (type === IconEnum.playCircle) return PlayCircle;
  if (type === IconEnum.plus) return Plus;
  if (type === IconEnum.plusCircle) return PlusCircle;
  if (type === IconEnum.minus) return Minus;
  if (type === IconEnum.minusCircle) return MinusCircle;
  if (type === IconEnum.pencil) return Pencil;
  if (type === IconEnum.modelHybrid) return ModelHybrid;
  if (type === IconEnum.modelPerformance) return ModelPerformance;
  if (type === IconEnum.modelSedanHatch) return ModelSedanHatch;
  if (type === IconEnum.modelSuv) return ModelSuv;
  if (type === IconEnum.sadFace) return SadFace;
  if (type === IconEnum.search) return Search;
  if (type === IconEnum.envelope) return Envelope;
  if (type === IconEnum.envelopeSolid) return EnvelopeSolid;
  if (type === IconEnum.phone) return Phone;
  if (type === IconEnum.mobile) return Mobile;
  if (type === IconEnum.phoneOutline) return PhoneOutline;
  if (type === IconEnum.compareCars) return CompareCars;
  if (type === IconEnum.basket) return Basket;
  if (type === IconEnum.car) return Car;
  if (type === IconEnum.user) return User;
  if (type === IconEnum.coin) return Coin;
  if (type === IconEnum.hornSpeaker) return HornSpeaker;
  if (type === IconEnum.pinLocation) return PinLocation;
  if (type === IconEnum.key) return Key;
  if (type === IconEnum.heart) return Heart;
  if (type === IconEnum.menu) return Menu;
  if (type === IconEnum.tools) return Tools;
  if (type === IconEnum.bullHorn) return BullHorn;
  if (type === IconEnum.clock) return Clock;
  if (type === IconEnum.wrench) return Wrench;
  if (type === IconEnum.cursor) return Cursor;
  if (type === IconEnum.info) return Info;
  if (type === IconEnum.tradeIn) return TradeIn;
  if (type === IconEnum.drive) return Drive;
  if (type === IconEnum.financeQuote) return FinanceQuote;
  if (type === IconEnum.pdfIcon) return PDFIcon;

  throw new Error(`Unknown icon type '${type}'.`);
};

interface IconProps {
  className?: string;
  type: keyof typeof IconEnum;
  color?: string;
  fill?: string;
  size?: number | string;
  onClick?: (e: any) => void;
}

type Props = IconProps & React.SVGProps<SVGAElement>;

export const SvgIcon = React.memo<Props>(
  ({
    type,
    className,
    color,
    fill,
    size,
    display = 'inline-block',
    onClick,
    strokeWidth,
    style,
  }) => {
    if (!!type && type === 'none') return null;

    const Component = getIconComponent(type);

    const getSize = () => {
      if (typeof size === 'string') return size;
      return `${size || 1}em`;
    };

    return (
      <i
        onClick={onClick}
        className={className}
        style={{
          ...{
            lineHeight: 1,
            height: 'fit-content',
            display: display as string,
          },
          ...style,
        }}
      >
        <Component
          width={getSize()}
          height={getSize()}
          color={color || '#000'}
          fill={fill || 'none'}
          style={{ verticalAlign: 'text-bottom' }}
          strokeWidth={strokeWidth}
        />
      </i>
    );
  }
);
