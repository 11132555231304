import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(0 24)"
      d="M235.959 9.664c4.426-12.885 23.654-12.885 28.08 0l48.883 142.621c1.992 5.755 7.647 9.67 14.04 9.67h158.255c14.286 0 20.237 17.335 8.68 25.282l-128.035 88.145c-5.164 3.565-7.327 9.88-5.36 15.634l48.907 142.622c4.401 12.885-11.139 23.58-22.72 15.635L258.68 361.128c-5.188-3.566-12.171-3.566-17.36 0l-128.01 88.145c-11.58 7.945-27.12-2.75-22.72-15.635l48.908-142.622c1.967-5.754-.197-12.069-5.36-15.634L6.103 187.237c-11.557-7.946-5.606-25.282 8.68-25.282h158.255c6.393 0 12.049-3.914 14.04-9.67z"
    />
  </svg>
);

const Memo = memo(SvgStar);
export default Memo;
