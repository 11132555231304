import * as React from 'react';
import { SVGProps } from 'react';
const SvgHornSpeaker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 18"
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M20 7.049c0-1.761-1.263-3.235-2.941-3.59V1.284c0-.593-.408-1.106-.992-1.247-.538-.13-1.106.1-1.413.57-.085.13-.164.273-.237.428-.858.423-5.11.623-6.54.69h-.012c-.474.023-.869.041-1.14.059l-.028.002h-.002c-.41.027-.799.053-1.168.09l-.037.004c-2.55.38-3.684 1.738-4.186 2.922A2.537 2.537 0 0 0 0 6.998c0 .91.511 1.753 1.303 2.197.543 1.28 1.578 2.186 3.028 2.653v3.306c0 1.294 1.066 2.346 2.377 2.346 1.31 0 2.377-1.052 2.377-2.346v-2.822c3.676.198 4.948.441 5.33.627.077.16.156.303.24.431.307.47.874.7 1.412.57.584-.142.992-.655.992-1.248v-2.075C18.737 10.283 20 8.81 20 7.05Zm-4.497 4.786c-.615-.537-2.247-.845-7.159-1.078l-.815-.039v4.436c0 .447-.368.81-.821.81a.817.817 0 0 1-.821-.81v-4.505l-.607-.135c-1.376-.307-2.234-1.002-2.624-2.127l-.118-.338L2.2 7.92a1.01 1.01 0 0 1-.644-.922c0-.398.265-.777.644-.922l.338-.129.118-.338c.43-1.24 1.426-1.962 3.047-2.208a34.69 34.69 0 0 1 1.098-.084l.027-.002c.259-.017.634-.034 1.083-.055l.036-.002c4.885-.229 6.831-.49 7.556-1.113v9.69Zm2.941-4.786c0-.907-.576-1.682-1.385-1.99v3.98a2.139 2.139 0 0 0 1.385-1.99Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = React.memo(SvgHornSpeaker);
export default Memo;
