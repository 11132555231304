import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { ModelArticlesInterface } from './store';
import TabMenu from './components/menu-tab';
import ModelContent from './components/model-content';
import cn from 'classnames';
import {
  Loading,
  SvgIcon,
  TooltipDisclaimer,
} from '~/common/components/ui-elements';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import { useIsMobile } from '~/common/hooks/use-is-mobile';

export const ModelArticles = React.memo((props: ModelArticlesInterface) => {
  const { title, categories, tooltip } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { isDesktop, isInnerWidth } = useIsMobile();

  const handleTabClick = (index: number) => {
    setSelectedTabIndex(index);
    setIsLoading(true);
  };

  const centerMode = React.useMemo(
    () =>
      (isDesktop() || isInnerWidth(801, 1024)) &&
      categories[selectedTabIndex].modelContents.length < 3,
    [isDesktop, isInnerWidth, categories, selectedTabIndex]
  );

  const responsive: ResponsiveType = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 801 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 300);
    return () => clearTimeout(timeout);
  }, [selectedTabIndex]);

  return (
    <div
      id="model-articles"
      className={cn('full-device-width', styles.modelArticles)}
    >
      <div className={styles.headerMenu}>
        <div className={styles.titleWrapper}>
          <TooltipDisclaimer
            triggerClassName={styles.tooltip}
            disclaimer={tooltip}
          >
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title || '' }}
            />
          </TooltipDisclaimer>
        </div>
        <div className={styles.tabMenuWrapper}>
          <TabMenu
            tabs={categories.map((category) => category.tabName)}
            selectedTabIndex={selectedTabIndex}
            onTabClick={handleTabClick}
          />
        </div>
      </div>
      <div className={styles.carouselWrapper}>
        {isLoading && <Loading height="100%" />}
        {!isLoading && (
          <Carousel
            partialVisible={false}
            swipeable
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            responsive={responsive}
            containerClass={cn(
              styles.carouselContainer,
              centerMode && styles.centerMode
            )}
            itemClass={styles.carouselItem}
            centerMode={false}
            autoPlaySpeed={3000}
            transitionDuration={500}
          >
            {categories[selectedTabIndex].modelContents.map(
              (modelContent, index) => (
                <div key={index} className={styles.carouselItem}>
                  <ModelContent modelContent={modelContent} />
                </div>
              )
            )}
          </Carousel>
        )}
      </div>
    </div>
  );
});

interface ArrowsProps {
  onClick?: () => void;
}

const CustomLeftArrow = ({ onClick }: ArrowsProps) => {
  return (
    <SvgIcon
      className={styles.customLeftArrow}
      type="chevronLeft"
      size={2}
      onClick={onClick}
    />
  );
};

const CustomRightArrow = ({ onClick }: ArrowsProps) => {
  return (
    <SvgIcon
      className={styles.customRightArrow}
      type="chevronRight"
      size={2}
      onClick={onClick}
    />
  );
};
