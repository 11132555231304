import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(37.04 0 0 29.67 -194.48 -106.038)"
      d="m17.74 10.57-.22-.2-.4-.35-.39.35-3.97 3.51V4.12h-1.52v9.76l-3.97-3.51-.39-.35-.4.35-.22.2-.51.45.51.44 5.34 4.74.4.35.4-.35 5.34-4.74.51-.44z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(37.04 0 0 29.67 22.574 427.143)"
      d="M0 1.91V0h12.29v1.91z"
    />
  </svg>
);

const Memo = memo(SvgDownload);
export default Memo;
