import * as React from 'react';
import { SVGProps } from 'react';
const SvgTradeIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={props.color}
      d="M18.5 0A18.5 18.5 0 1 0 37 18.5 18.5 18.5 0 0 0 18.5 0zm0 34.69A16.19 16.19 0 1 1 34.69 18.5 16.21 16.21 0 0 1 18.5 34.69z"
    />
    <path
      fill={props.color}
      d="M27.73 14.45a1 1 0 0 0-.27 0l-1 .28-1.07-2.53a2.3 2.3 0 0 0-2-1.32h-9.83a2.3 2.3 0 0 0-2 1.32l-1.05 2.56-1-.28a1 1 0 0 0-.27 0 .82.82 0 0 0-.83.87v.6A1.07 1.07 0 0 0 9.51 17h.11l-.17.41A8 8 0 0 0 9 20v5.1a1.07 1.07 0 0 0 1 1.02h1.4a1.07 1.07 0 0 0 1.07-1.07v-1.27h12v1.27a1.07 1.07 0 0 0 1.07 1.07H27a1.07 1.07 0 0 0 1-1.07V20a8 8 0 0 0-.5-2.55l-.14-.45h.13a1.07 1.07 0 0 0 1.07-1.07v-.6a.82.82 0 0 0-.83-.87zm-16.43 2l1.59-3.89a1.38 1.38 0 0 1 1.18-.79h8.83a1.38 1.38 0 0 1 1.18.79l1.6 3.89a.53.53 0 0 1-.53.79H11.84a.53.53 0 0 1-.53-.79zm3.56 4.91a.43.43 0 0 1-.43.43h-3a.43.43 0 0 1-.43-.43v-1.49a.43.43 0 0 1 .43-.43h3a.43.43 0 0 1 .43.43zm11.11 0a.43.43 0 0 1-.43.43h-3a.43.43 0 0 1-.43-.43v-1.49a.43.43 0 0 1 .43-.43h3a.43.43 0 0 1 .43.43zm0 0"
    />
  </svg>
);
const Memo = React.memo(SvgTradeIn);
export default Memo;
