import React from 'react';

export const useIsMobile = () => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const isMobile = () => {
    if (typeof window === 'undefined') return false;
    return window.innerWidth < 768;
  };

  const isMobileOrSmallTablet = () => {
    if (typeof window === 'undefined') return false;
    return window.innerWidth < 992;
  };

  const isTablet = () => {
    if (typeof window === 'undefined') return false;
    return window.innerWidth >= 768 && window.innerWidth <= 1024;
  };

  const isInnerWidth = (min, max) => {
    if (typeof window === 'undefined') return false;
    return window.innerWidth >= min && window.innerWidth <= max;
  };

  const isDesktop = () => {
    if (typeof window === 'undefined') return false;
    return window.innerWidth > 1024;
  };

  return {
    containerRef,
    isMobile,
    isTablet,
    isInnerWidth,
    isDesktop,
    isMobileOrSmallTablet,
  };
};
