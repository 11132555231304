import _ from 'lodash';

export const getParameterByName = (
  name: string,
  url: string = window.location.href
) => {
  const newName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const isYoutubeUrl = (url: string) => {
  const p =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const matches = url.match(p);
  return !!matches;
};

export const isAbsoluteUrl = (url: string) => {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i');
  return r.test(url);
};

export const joinPath = (a: string, b: string, c = '/') => {
  return `${_.trimEnd(a, '/')}${
    !!b && b.charAt(0) !== '?' ? c : ''
  }${_.trimStart(b, '/')}`;
};
