import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgSadFace = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="sadFaceIconTitle"
    stroke={props.color}
    strokeLinecap="square"
    fill="none"
    color={props.color}
    {...props}
  >
    <path strokeLinecap="round" d="M9 9h0M15 9h0" />
    <path d="M8 16c1.333-.667 2.666-1 3.997-.999 1.335 0 2.67.332 4.003.999" />
    <circle cx={12} cy={12} r={10} />
  </svg>
);
const Memo = memo(SvgSadFace);
export default Memo;
