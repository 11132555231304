import * as React from 'react';
import { SVGProps } from 'react';
const SvgKey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M6.266 6.859C6.266 3.077 9.346 0 13.134 0 16.92 0 20 3.075 20 6.857c0 3.783-3.08 6.86-6.866 6.86a6.86 6.86 0 0 1-1.61-.198v.614a.755.755 0 0 1-.755.755H8.587v1.868a.755.755 0 0 1-.756.755H6.095v1.734a.755.755 0 0 1-.756.755H.756A.755.755 0 0 1 0 19.245V16.4c0-.197.078-.388.217-.529l6.496-6.596a6.786 6.786 0 0 1-.447-2.416Zm6.868-5.35a5.359 5.359 0 0 0-5.356 5.35s-.012 1.073.397 2.026c.348.808-.165 1.227-.165 1.227l-6.498 6.599v1.78h3.072v-1.734c0-.416.338-.754.756-.754h1.737v-1.869c0-.416.337-.754.755-.754h2.182v-.89a.755.755 0 0 1 1.052-.695 5.358 5.358 0 0 0 7.423-4.936c0-2.95-2.402-5.35-5.355-5.35Zm1.314 1.336A2.73 2.73 0 0 0 11.72 5.57a2.73 2.73 0 0 0 5.457 0 2.73 2.73 0 0 0-2.73-2.725ZM13.232 5.57a1.216 1.216 0 0 0 2.434 0 1.218 1.218 0 0 0-2.434 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = React.memo(SvgKey);
export default Memo;
