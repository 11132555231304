import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface TextAreaProps {
  label?: string;
  error?: string;
}

type Props = TextAreaProps & React.HTMLProps<HTMLTextAreaElement>;

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ name, label, error, onChange, ...props }, ref) => {
    const [isActive, setIsActive] = React.useState(false);

    const inputClass = cn(styles.Input, {
      [styles.Input__active]: isActive,
      [styles.Input__error]: !!error,
    });

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setIsActive(event.target.value !== '');
      if (onChange) onChange(event);
    };

    return (
      <div className={inputClass}>
        <textarea
          {...props}
          ref={ref}
          name={name}
          className={styles.Input__inner}
          onChange={handleChange}
        />
        {label && (
          <label htmlFor={name} className={styles.Input__label}>
            {label}
          </label>
        )}
        {error && <span className={styles.Input__errorMsg}>{error}</span>}
      </div>
    );
  }
);
