import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: props.color,
        strokeWidth: props.strokeWidth,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.fill,
        fillRule: 'nonzero',
        opacity: props.opacity,
        overflow: props.overflow as string,
      }}
      d="M500.203 236.907 30.869 2.24a21.362 21.362 0 0 0-20.736.939C3.84 7.083 0 13.931 0 21.333v469.333c0 7.403 3.84 14.251 10.133 18.155a21.267 21.267 0 0 0 11.2 3.179c3.264 0 6.528-.747 9.536-2.24l469.333-234.667C507.435 271.467 512 264.085 512 256s-4.565-15.467-11.797-19.093z"
    />
  </svg>
);
const Memo = memo(SvgPlay);
export default Memo;
