export const screenXs = 0;
export const screenSm = 640;
export const screenMd = 768;
export const screenLg = 1024;
export const screenXl = 1280;

export const mobileMax = screenMd - 1;
export const tabletMin = screenMd;
export const tabletMax = screenLg - 1;
export const desktopMin = screenLg;

export const screenXsMax = screenSm - 1;
export const screenSmMax = screenMd - 1;
export const screenMdMax = screenLg - 1;
export const screenLgMax = screenXl - 1;

export const colorPitchBlack = '000000';
export const colorBlack = '323334';
export const colorWhite = 'ffffff';
export const colorGreyLight = 'f2f3f4';
export const colorGrey = '9b9b9b';
export const colorPrimary = '1637a0';
export const colorPrimaryDark = '012059';
export const colorPrimaryLight = '406dbb';
export const colorPrimaryLighter = '9bb4dd';
export const colorPrimaryVeryLight = 'c5d3eb';
export const colorGreen = '48aa5f';
export const colorError = 'e74c3c';
export const colorErrorInputBg = 'ffdcdc';

export const colorSuccess = colorPrimaryLight;
export const colorBgConcrete = 'ebebec';
export const colorBgConcrete2 = colorGreyLight;
export const colorText = colorBlack;
export const colorBorder = '979797';
