import * as React from 'react';
import { SVGProps } from 'react';
const SvgPinLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 13 19"
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M0 6.529C0 2.929 2.916 0 6.5 0S13 2.929 13 6.529c0 1.18-.317 2.337-.917 3.345l-5.028 8.433a.541.541 0 0 1-.46.264H6.59a.541.541 0 0 1-.46-.257L.97 9.962A6.537 6.537 0 0 1 0 6.53Zm6.582 10.453 4.571-7.667c.5-.839.767-1.802.767-2.786 0-3-2.433-5.448-5.42-5.448S1.076 3.529 1.076 6.529c0 1.014.287 2.003.815 2.86l4.691 7.593Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M3.22 6.5A3.285 3.285 0 0 1 6.5 3.22 3.285 3.285 0 0 1 9.781 6.5a3.275 3.275 0 0 1-3.28 3.281 3.277 3.277 0 0 1-3.282-3.28Zm1.086 0a2.192 2.192 0 1 0 4.386 0A2.2 2.2 0 0 0 6.5 4.306 2.202 2.202 0 0 0 4.306 6.5Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = React.memo(SvgPinLocation);
export default Memo;
