import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      d="m-10 .64 6.85 6.86L10-5.64 8.11-7.5-3.15 3.75l-4.99-4.99z"
      transform="matrix(25 0 0 25 250 250)"
    />
  </svg>
);

const Memo = memo(SvgCheck);
export default Memo;
