import React from 'react';
import cn from 'classnames';
import { SvgIcon } from '@ui-elements';
import styles from './index.module.scss';

interface Props {
  title: string;
  content?: string;
  className?: string;
  titleClass?: string;
  contentClass?: string;
  onExpandChange?: (expanded: boolean) => void;
  expanded?: boolean;
}

export const AccordionItem: React.FC<Props> = ({
  title,
  content,
  children,
  expanded,
  className,
  onExpandChange,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(
    expanded || false
  );

  React.useEffect(() => setIsExpanded(Boolean(expanded)), [expanded]);

  return (
    <div
      className={cn(styles.accordionItem, className, {
        [styles.accordionItemExpanded]: isExpanded,
      })}
    >
      <div
        className={styles.accordionItemHeader}
        onClick={() => {
          const newExpanded = !isExpanded;
          setIsExpanded(newExpanded);
          if (typeof onExpandChange !== 'undefined')
            onExpandChange(newExpanded);
        }}
      >
        <div
          className={cn(styles.accordionItemTitle, props.titleClass)}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className={styles.accordionItemIcon}>
          <SvgIcon type={isExpanded ? 'chevronUp' : 'chevronDown'} />
        </div>
      </div>
      {!!children ? (
        <div className={cn(styles.accordionItemContent, props.contentClass)}>
          {expanded && children}
        </div>
      ) : (
        <div
          className={cn(styles.accordionItemContent, props.contentClass)}
          dangerouslySetInnerHTML={{ __html: content || '' }}
        />
      )}
    </div>
  );
};
