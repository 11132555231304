import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(0 77)"
      d="M250 0c111.741 0 211.871 67.649 249.164 168.34a13.413 13.413 0 0 1 0 9.32C461.871 278.351 361.742 346 250 346 138.258 346 38.129 278.352.835 177.66a13.413 13.413 0 0 1 0-9.32C38.13 67.649 138.258 0 250 0zm0 28.833C153.349 28.833 66.48 86.57 32.193 173 66.48 259.43 153.35 317.166 250 317.166c96.65 0 183.52-57.737 217.807-144.167C433.52 86.57 346.65 28.833 250 28.833zm-4.167 55.744c52.845 0 95.833 39.663 95.833 88.422 0 48.76-42.988 88.423-95.833 88.423S150 221.759 150 172.999c0-48.758 42.988-88.422 95.833-88.422zm0 29.475c-35.235 0-63.89 26.436-63.89 58.947 0 32.51 28.654 58.948 63.89 58.948 35.235 0 63.89-26.438 63.89-58.948 0-32.51-28.654-58.947-63.89-58.947z"
    />
  </svg>
);

const Memo = memo(SvgEye);
export default Memo;
