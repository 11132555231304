import React, { memo, SVGProps } from 'react';

const SvgPDFIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
    {...props}
  >
    <defs>
      <style>
        {`
          .cls-1 { fill: #1d252c; }
          .cls-2 { fill: #297cdf; }
        `}
      </style>
    </defs>
    <title>elements_v2</title>
    <path className="cls-1" d="M2,3.67,0,3.65,2.7.92V3A.73.73,0,0,1,2,3.67Z" />
    <path
      className="cls-1"
      d="M7.75,9.79c.59.05,1.3.2,1.48.64a.71.71,0,0,1-.07.69.39.39,0,0,1-.33.19h0c-.11,0-.46,0-1.3-1.05H7.08a9.43,9.43,0,0,0-2.91.94c-.06.11-.11.22-.17.31l-.08.13c-.5.87-1,1.67-1.53,1.67a.43.43,0,0,1-.37-.2.66.66,0,0,1-.13-.49c.08-.48.63-.92,1.11-1.25H3a9.22,9.22,0,0,1,.81-.5,18.81,18.81,0,0,0,1-3H4.69A1.19,1.19,0,0,1,3.5,6.63V3A1.18,1.18,0,0,1,4.69,1.84h5.84V1.7A.82.82,0,0,0,9.7.9H3.16L3.14,3A1.18,1.18,0,0,1,2,4.13H0v9a.82.82,0,0,0,.83.8h9.69c0-.41,0-3.28,0-6.13H6.42A19.62,19.62,0,0,0,7.75,9.79Z"
    />
    <path
      className="cls-1"
      d="M5.91,7.76h-.6a19,19,0,0,1-.83,2.73h0v0A10.38,10.38,0,0,1,7,9.77h.13l0,0h0A18,18,0,0,1,5.91,7.76Z"
    />
    <path
      className="cls-1"
      d="M8.86,10.63c0-.11-.27-.23-.64-.31.33.36.53.49.61.5A.35.35,0,0,0,8.86,10.63Z"
    />
    <path
      className="cls-1"
      d="M2.36,12.67a.19.19,0,0,0,0,.12l0,0c.11,0,.38-.15,1-1.19l-.12.1h0C2.7,12.12,2.39,12.45,2.36,12.67Z"
    />
    <path
      className="cls-2"
      d="M14.22,2.35H4.84a.8.8,0,0,0-.79.8V6.46a.8.8,0,0,0,.79.8H14.2a.8.8,0,0,0,.8-.8V3.15A.79.79,0,0,0,14.22,2.35ZM8.08,4.84a.94.94,0,0,1-.67.2H7V6H6.25V3.54H7.48a.86.86,0,0,1,.61.19.69.69,0,0,1,.21.55A.77.77,0,0,1,8.08,4.84Zm2.75.48a.93.93,0,0,1-.25.36.7.7,0,0,1-.33.19,1.45,1.45,0,0,1-.44.06H8.7V3.53H9.81a1.33,1.33,0,0,1,.53.09.81.81,0,0,1,.33.25,1.57,1.57,0,0,1,.19.39,1.41,1.41,0,0,1,.06.47A1.55,1.55,0,0,1,10.83,5.32ZM13.16,4h-1.1v.42H13V5h-.94v1h-.75V3.53h1.85Z"
    />
    <path
      className="cls-2"
      d="M7.22,4H7v.53h.19a.46.46,0,0,0,.29-.08.22.22,0,0,0,.1-.19A.26.26,0,0,0,7.5,4.1C7.45,4,7.36,4,7.22,4Z"
    />
    <path
      className="cls-2"
      d="M9.62,4.07H9.44V5.38h.18a.85.85,0,0,0,.33,0,.31.31,0,0,0,.16-.19,1.29,1.29,0,0,0,.06-.42.86.86,0,0,0-.12-.53A.59.59,0,0,0,9.62,4.07Z"
    />
  </svg>
);

const Memo = memo(SvgPDFIcon);
export default Memo;
