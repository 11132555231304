import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(25 0 0 25 87.5 12.5)"
      d="M12.092 9.655 6.5 19 .908 9.655C-.267 7.691-.304 5.315.81 3.298 1.883 1.358 3.775.161 6 .016a7.683 7.683 0 0 1 .997 0c2.227.145 4.119 1.342 5.191 3.282 1.115 2.017 1.078 4.393-.097 6.357"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: !!props.fill ? props.fill : '#fff',
        fillRule: 'evenodd',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(25 0 0 25 87.5 12.5)"
      d="M3 6.5C3 8.43 4.57 10 6.5 10S10 8.43 10 6.5 8.43 3 6.5 3 3 4.57 3 6.5Z"
    />
  </svg>
);

const Memo = memo(SvgLocation);
export default Memo;
