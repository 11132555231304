import React from 'react';
import cn from 'classnames';
import { SvgIcon } from '../svg-icon';
import styles from './index.module.scss';

interface RatingProps {
  rating: number;
  className?: string;
  size?: string;
}

export const Rating = React.memo<RatingProps>(({ rating, className, size }) => {
  return (
    <div className={cn(styles.rating, className)}>
      <div className={styles.ratingWrapper}>
        <div className={styles.ratingValue}>{rating}</div>
        <div className={styles.ratingStarsContainer}>
          <div className={styles.ratingContainerEmpty}>
            {[...Array(5)].map((_, index) => {
              return (
                <SvgIcon
                  key={index}
                  type="star"
                  fill="none"
                  size={size ?? '14px'}
                  color="#c7c7c7"
                />
              );
            })}
          </div>

          <div
            className={styles.ratingContainer}
            style={{
              width: `${100 * (rating / 5)}%`,
            }}
          >
            {[...Array(5)].map((_, index) => {
              return (
                <SvgIcon
                  key={index}
                  type="star"
                  fill="none"
                  size={size ?? '14px'}
                  color="#1637A0"
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});
