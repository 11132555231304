import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface TabProps {
  value: string;
  active?: boolean;
  onClick?: (value: string) => void;
  className?: string;
}

export const TabButton: React.FC<TabProps> = ({
  value,
  active,
  onClick,
  children,
  className,
}) => {
  const handleClick = () => {
    if (onClick) onClick(value);
  };

  return (
    <button
      type="button"
      className={cn(className, active ? styles.TabActive : styles.Tab)}
      onClick={handleClick}
      key={value}
    >
      {children ? children : value}
    </button>
  );
};
