import React, { CSSProperties } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface Props {
  className?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  style?: CSSProperties | undefined;
  onClick?: () => void;
}

export const Card = React.memo<Props>(
  ({ children, className, contentClassName, style, onClick }) => (
    <div style={style} className={cn(styles.card, className)} onClick={onClick}>
      <div className={cn(styles.cardContent, contentClassName)}>{children}</div>
    </div>
  )
);
