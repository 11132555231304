import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgTireRim = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(0 18)"
      d="M250 0c138.187 0 250 103.781 250 232 0 128.238-111.833 232-250 232C111.813 464 0 360.219 0 232 0 103.762 111.832 0 250 0zm0 27.188c-121.994 0-220.703 91.62-220.703 204.812 0 113.21 98.729 204.812 220.703 204.812 121.994 0 220.703-91.62 220.703-204.812 0-113.21-98.729-204.812-220.703-204.812zm0 65.612c83.023 0 150 62.731 150 139.2 0 76.441-66.902 139.2-150 139.2-83.24 0-150-62.91-150-139.2 0-76.53 67.019-139.2 150-139.2zm-37.686 174.846-59.237 31.739c19.395 23.964 48.854 40.526 82.5 44.246v-63.497c-8.888-2.296-16.855-6.652-23.263-12.488zm75.372 0c-6.408 5.836-14.375 10.193-23.263 12.488v63.497c33.646-3.721 63.105-20.283 82.5-44.246zM138.618 187.78c-6.285 13.58-9.772 28.529-9.772 44.221 0 15.692 3.487 30.64 9.772 44.222l59.237-31.738A46.513 46.513 0 0 1 196.154 232a46.5 46.5 0 0 1 1.701-12.482zm222.764 0-59.237 31.738A46.513 46.513 0 0 1 303.846 232c0 4.309-.59 8.492-1.701 12.483l59.237 31.738c6.285-13.58 9.772-28.529 9.772-44.221 0-15.692-3.487-30.64-9.772-44.221zM250 208.8c-13.785 0-25 10.407-25 23.2s11.215 23.2 25 23.2c13.786 0 25-10.407 25-23.2s-11.214-23.2-25-23.2zm14.423-88.43v63.497c8.888 2.296 16.855 6.652 23.263 12.488l59.237-31.739c-19.395-23.964-48.854-40.526-82.5-44.246zm-28.846 0c-33.646 3.72-63.105 20.282-82.5 44.246l59.237 31.738c6.408-5.836 14.376-10.193 23.263-12.488z"
    />
  </svg>
);

const Memo = memo(SvgTireRim);
export default Memo;
