import React, { useEffect } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface CheckboxProps {
  value?: boolean;
  children?: React.ReactNode;
  onChange?: (value?: boolean) => void;
  className?: string;
  error?: string;
}

type Props = Omit<
  React.HTMLProps<HTMLInputElement>,
  'value' | 'checked' | 'onChange'
> &
  CheckboxProps;

export const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  ({ label, children, className, value, error, onChange, ...rest }, ref) => {
    const [isChecked, setChecked] = React.useState(value);

    useEffect(() => {
      setChecked(value);
    }, [value]);

    const handleChange = (checked?: boolean) => {
      setChecked(checked);

      if (onChange) {
        onChange(checked);
      }
    };

    return (
      <div className={cn(className, styles.Checkbox)}>
        <input
          {...rest}
          ref={ref}
          type="checkbox"
          checked={isChecked}
          onChange={(e) => handleChange(e.target.checked)}
        />

        <label htmlFor={rest.name} onClick={() => handleChange(!isChecked)}>
          {children ? children : label}
          {error && <span className={styles.Checkbox_error}>{error}</span>}
        </label>
      </div>
    );
  }
);
