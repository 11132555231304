import React, { useState, useRef } from 'react';
import cn from 'classnames';
import 'intersection-observer';
import {
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useFloating,
  useInteractions,
  FloatingPortal,
  FloatingArrow,
  autoUpdate,
  arrow,
  offset,
  flip,
  shift,
} from '@floating-ui/react';
import styles from './index.module.scss';

type ChildParam = {
  setReference(node: Element | null): void;
  getReferenceProps(): Record<string, unknown>;
  renderTrigger: () => React.ReactNode;
};

interface FloatingDisclaimerProps {
  className?: string;
  triggerClassName?: string;
  children: (param: ChildParam) => React.ReactNode;
  disclaimer?: string;
  noWrapperElement?: boolean;
  wrapperStyle?: React.CSSProperties;
}

export const FloatingDisclaimer = React.memo<FloatingDisclaimerProps>(
  ({
    className,
    triggerClassName,
    children,
    disclaimer,
    noWrapperElement,
    wrapperStyle,
  }) => {
    const arrowRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: 'top',
      whileElementsMounted: autoUpdate,
      middleware: [
        shift(),
        offset(5),
        arrow({ element: arrowRef }),
        flip({ fallbackAxisSideDirection: 'end' }),
      ],
    });

    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const hover = useHover(context, { move: false });
    const role = useRole(context, { role: 'tooltip' });
    const { getReferenceProps, getFloatingProps } = useInteractions([
      hover,
      focus,
      dismiss,
      role,
    ]);

    const renderTrigger = () => {
      return (
        !!disclaimer && (
          <span
            ref={refs.setReference}
            {...getReferenceProps()}
            className={cn(styles.disclaimerTrigger, triggerClassName)}
          >
            *
          </span>
        )
      );
    };

    return (
      <>
        {noWrapperElement ? (
          children({
            setReference: refs.setReference,
            getReferenceProps,
            renderTrigger,
          })
        ) : (
          <div style={wrapperStyle} className={className}>
            {children({
              setReference: refs.setReference,
              getReferenceProps,
              renderTrigger,
            })}
          </div>
        )}

        {!!disclaimer && (
          <FloatingPortal>
            {isOpen && (
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
                className={styles.disclaimerTooltip}
              >
                <FloatingArrow ref={arrowRef} context={context} fill="#fff" />
                <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
              </div>
            )}
          </FloatingPortal>
        )}
      </>
    );
  }
);
