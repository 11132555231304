import loadable from '@loadable/component';
export { IconEnum } from './svg-icon';

export * from './accordion';
export * from './accordion-item';
export * from './button';
export * from './breadcrumbs';
export * from './card';
export * from './input';
export * from './modal';
export * from './slider';
export * from './tab-button';
export * from './textarea';
export * from './checkbox';
export * from './loading';
export * from './floating-disclaimer';
export * from './rating';
export * from './radio-button';

export const Select = loadable(() => import('react-select'));
export const Dropdown = loadable(() => import('./dropdown'), {
  resolveComponent: (component) => component.Dropdown,
});

export const Autocomplete = loadable(() => import('./auto-complete'), {
  resolveComponent: (component) => component.Autocomplete,
});

export const SvgIcon = loadable(() => import('./svg-icon'), {
  resolveComponent: (component) => component.SvgIcon,
});

export const Carousel = loadable(() => import('react-responsive-carousel'), {
  resolveComponent: (component) => component.Carousel,
});

export const CustomCarousel = loadable(() => import('./custom-carousel'), {
  resolveComponent: (component) => component.CustomCarousel,
});

export const AnimatedNumber = loadable(() => import('./animated-number'), {
  resolveComponent: (component) => component.AnimatedNumber,
});

export const TooltipDisclaimer = loadable(() => import('./popup-disclaimer'), {
  resolveComponent: (component) => component.TooltipDisclaimer,
});

export const Button = loadable(() => import('./button'), {
  resolveComponent: (component) => component.Button,
});

export const RadioButton = loadable(() => import('./radio-button'), {
  resolveComponent: (component) => component.RadioButton,
});

export const FileUpload = loadable(() => import('./file-upload'), {
  resolveComponent: (component) => component.FileUpload,
});

export const DatePickerWrapper = loadable(() => import('./date-picker'), {
  resolveComponent: (component) => component.DatePickerWrapper,
});
