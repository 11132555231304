import * as React from 'react';
import { SVGProps } from 'react';
const SvgTools = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={props.color}
      d="M8.22229 17.3583C7.77175 16.7201 7.13886 16.2297 6.40405 15.9449V7.85472C7.58403 7.18494 8.66746 5.82428 8.65137 4.09971C8.65137 2.0429 7.4553 0.518743 5.46007 0.0282716C5.17044 -0.0402889 4.88081 0.128476 4.80572 0.413266C4.78963 0.466004 4.78427 0.524017 4.78963 0.58203L4.96663 2.85508C4.51073 3.0924 3.95828 3.0924 3.50238 2.85508L3.69547 0.571482C3.72229 0.281418 3.50238 0.0282716 3.20739 0.00190219C3.14303 -0.0033717 3.07867 0.00190219 3.0143 0.0229977C2.11859 0.270871 1.33552 0.814081 0.793802 1.5577C0.284267 2.26967 0.0107271 3.14514 0 4.08917C0 5.03319 0.262813 5.91393 0.777712 6.62591C1.22825 7.25877 1.86114 7.74925 2.59595 8.03404V16.1558C1.25507 16.9311 0.337902 18.4078 0.337902 19.9267C0.337902 20.8971 0.616806 21.7778 1.14243 22.4739C1.7056 23.2228 2.54768 23.7397 3.57211 23.9717C3.86174 24.035 4.15137 23.861 4.21573 23.5762C4.22646 23.5234 4.23182 23.4707 4.22646 23.418L4.04946 21.1449C4.50536 20.9076 5.05781 20.9076 5.51371 21.1449L5.32062 23.4285C5.2938 23.7186 5.51371 23.9717 5.8087 23.9981C5.87306 24.0034 5.93743 23.9981 6.00179 23.977C6.8975 23.7291 7.68594 23.1859 8.22229 22.437C8.73182 21.7198 9 20.8443 9 19.895C9 18.951 8.73719 18.0702 8.22229 17.3583Z"
    />
    <path
      fill={props.color}
      d="M18.4652 13.7016L15.6631 13.6911V4.39797H16.77C17.0695 4.39274 17.3101 4.14667 17.3048 3.85348C17.3048 3.83253 17.3048 3.81159 17.2994 3.79065L16.8074 0.439893C16.77 0.183351 16.5401 -0.00512912 16.278 0.000106438H13.877C13.615 -0.00512912 13.3851 0.183351 13.3476 0.439893L12.8557 3.79588C12.8129 4.08384 13.0161 4.35609 13.3155 4.39797C13.3369 4.40321 13.3637 4.40321 13.3851 4.40321H14.5936V13.6911L11.5348 13.6649C11.2354 13.6701 10.9947 13.911 11.0001 14.1989C11.0054 14.4869 11.2407 14.7173 11.5348 14.7225L12.5616 14.7277V21.534C12.5616 22.89 13.6792 23.9894 15.0642 23.9999C15.0695 23.9999 15.0749 23.9999 15.0856 23.9999C16.4706 23.9999 17.5935 22.9004 17.5935 21.5392V21.534V14.7434L18.4652 14.7644C18.7646 14.7591 19.0053 14.5183 18.9999 14.2199C18.9892 13.9372 18.7539 13.7068 18.4652 13.7016Z"
    />
  </svg>
);
const Memo = React.memo(SvgTools);
export default Memo;
