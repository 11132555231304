import * as React from 'react';
import { SVGProps, memo } from 'react';

const Clock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    strokeWidth={0}
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
  </svg>
);
const Memo = memo(Clock);
export default Memo;
