import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgModelPerformance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: props.color,
        strokeWidth: 0.3,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(0 165)"
      d="M474.932 76.143c-5.092-4.444-39.169-18.366-135.133-29.672-3.003-.327-10.249-4.51-18.606-9.347C297.17 23.203 257.021 0 210.28 0c-63.454 0-76.706 5.163-128.997 29.738-5.549 2.745-18.93 6.013-51.377 6.013C18.09 35.751.006 65.032.006 101.046c0 11.7 3.982 19.085 6.724 22.68l-5.679 5.36a3.136 3.136 0 0 0-1.044 2.548c.065.916.522 1.83 1.24 2.419.914.719 22.85 16.993 65.935 17.45C75.408 162.68 88.594 170 103.544 170c14.884 0 28.071-7.32 36.297-18.431l250.812 1.437C398.944 163.333 411.61 170 425.84 170c14.297 0 27.027-6.666 35.317-17.06 7.442-.588 38.843-5.162 38.843-41.242 0-10.654-3.264-15.294-25.068-35.555zm-371.388 87.321c-21.347 0-38.712-17.386-38.712-38.758 0-21.373 17.365-38.693 38.712-38.693 21.347 0 38.647 17.32 38.647 38.693 0 21.372-17.3 38.758-38.647 38.758zm322.296 0c-21.282 0-38.647-17.386-38.647-38.758 0-21.373 17.365-38.693 38.647-38.693 21.347 0 38.712 17.32 38.712 38.693 0 21.372-17.365 38.758-38.712 38.758zm40.018-17.713a44.856 44.856 0 0 0 5.222-21.046c0-24.967-20.303-45.229-45.24-45.229-24.872 0-45.175 20.262-45.175 45.229 0 7.908 2.024 15.294 5.549 21.7l-242.326-1.372c3.068-6.144 4.831-13.007 4.831-20.327 0-24.967-20.237-45.229-45.175-45.229s-45.24 20.262-45.24 45.229c0 7.256 1.762 14.052 4.765 20.065-29.05-1.045-47.46-9.673-54.51-13.79l4.896-4.641c.718-.589 1.044-1.503 1.044-2.419 0-.914-.392-1.764-1.11-2.418-.26-.195-6.855-6.34-6.855-20.458 0-35.033 17.56-58.758 23.371-58.758 27.092 0 45.371-2.222 54.25-6.667C134.16 12.091 146.105 6.536 210.277 6.536c44.98 0 84.214 22.68 107.65 36.209 10.12 5.883 16.777 9.673 21.086 10.261 95.442 11.177 128.083 24.967 131.543 27.974 22 20.458 22.914 23.268 22.914 30.72 0 25.228-17.756 32.156-27.614 34.052z"
      strokeLinecap="round"
    />
  </svg>
);

const Memo = memo(SvgModelPerformance);
export default Memo;
