import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 1400 1400"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path d="M0 0h7.472v15.053H0V0Z" id="chevron-down_svg__a" />
    </defs>
    <g transform="matrix(-87.5 0 0 -87.5 1355.233 1055.356)">
      <g transform="rotate(-90 3.892 3.844)" fill="none" fillRule="evenodd">
        <mask id="chevron-down_svg__b" fill="#fff">
          <use xlinkHref="#chevron-down_svg__a" />
        </mask>
        <path
          d="m0 .724 5.618 6.704L0 14.132l1.189.724L7.41 7.428 1.19 0Z"
          fill={props.color}
          mask="url(#chevron-down_svg__b)"
        />
      </g>
    </g>
  </svg>
);

const Memo = memo(SvgChevronDown);
export default Memo;
