import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: props.color,
        strokeWidth: props.strokeWidth,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.fill || 'none',
        fillOpacity: 0,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(275 275)"
      d="m-.5-200 1 350"
      strokeLinecap="round"
      fill="none"
    />
    <path
      style={{
        stroke: props.color,
        strokeWidth: props.strokeWidth,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.fill || 'none',
        fillOpacity: 0,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(300 250)"
      d="m-200-.5 350 1"
      strokeLinecap="round"
      fill="none"
    />
  </svg>
);

const Memo = memo(SvgPlus);
export default Memo;
