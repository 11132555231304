import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgModelHybrid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'evenodd',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.16 0 0 13.17 6.574 138.052)"
      d="M15.372 6.572H20.1l-3.27 6.54 1.111.01 3.776-7.55h-4.736l1.856-3.754a12.404 12.404 0 0 0-.929-.377l-2.18 4.409-.356.722Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'evenodd',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.16 0 0 13.17 6.585 138.052)"
      d="m12.911 8.572 3.684-7.45a14.239 14.239 0 0 0-1.059-.115L11.658 8.85l-.356.722h4.727l-1.757 3.513 1.112.012 2.262-4.525h-4.735Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'evenodd',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.16 0 0 13.17 6.602 138.045)"
      d="M8.424 16.066c-1.55 0-2.814-1.302-2.814-2.897 0-1.595 1.265-2.896 2.814-2.896 1.55 0 2.815 1.301 2.815 2.896s-1.265 2.897-2.815 2.897Zm3.74-3c-.053-2.067-1.714-3.728-3.73-3.728-1.99 0-3.641 1.633-3.733 3.661l-2.77-.254a.442.442 0 0 1-.257-.123l-.725-.689a.188.188 0 0 1-.054-.123v-.094h-.01v-1.567c0-.037.018-.075.047-.113l.302-.358a1.25 1.25 0 0 0 .302-.783l.074-2.312c.018-.415.174-.802.449-1.095l3.173-3.236a.43.43 0 0 0 .129-.425l-.038-.179.936-.113c.995-.095 5.767-.795 9.277-.526l.45-.909C12.284-.266 7.18.504 6.149.61h-.01L5.132.732a.812.812 0 0 0-.578.35.799.799 0 0 0-.147.67l.01.028-3.008 3.057a2.623 2.623 0 0 0-.715 1.736L.619 8.885a.297.297 0 0 1-.082.208l-.302.358a.99.99 0 0 0-.23.737v1.613c0 .302.119.595.34.802l.724.689c.202.198.477.33.77.368l2.954.273C5.15 15.717 6.673 17 8.443 17c1.752 0 3.264-1.265 3.64-3.019l1.734.019.456-.913-2.108-.021Z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'evenodd',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.16 0 0 13.17 6.552 138.042)"
      d="M28.662 16.064c-1.55 0-2.815-1.302-2.815-2.897 0-1.595 1.265-2.896 2.815-2.896s2.815 1.301 2.815 2.896-1.265 2.897-2.815 2.897M25.847 5.751a.475.475 0 0 0 .294.104c.008 0 .862 0 1.127.018 3.833.293 6.071.868 7.061 1.18.128.047.431.358.623.604l.01.01.054.056c.257.226.533.981.633 1.424l.02.048a.318.318 0 0 0 .054.113l.21.32a.634.634 0 0 1 .101.397l-.2 2.784a.42.42 0 0 0 .072.301l.01.01a.712.712 0 0 1-.34.123l-3.219.33c.02-.132.028-.264.028-.396 0-2.114-1.678-3.84-3.732-3.84-2.054 0-3.732 1.726-3.722 3.84v.018l-6.99-.072-.457.915 7.574.082c.431 1.698 1.908 2.878 3.613 2.878 1.541 0 2.926-.99 3.485-2.472l3.53-.358a1.65 1.65 0 0 0 1.027-.52l.156-.16a.483.483 0 0 0 .046-.604l-.138-.189.193-2.604a1.694 1.694 0 0 0-.257-.99l-.165-.255c-.082-.33-.376-1.34-.862-1.803-.137-.179-.596-.745-1.054-.896-1.009-.32-3.31-.906-7.263-1.208a19.222 19.222 0 0 0-1.072-.028l-.056-.038C24.395 3.73 21.42 1.951 19.253.977l-.416.841c2.363 1.06 5.822 3.188 7.01 3.933"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'evenodd',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.16 0 0 13.17 6.567 138.064)"
      d="m18.837 1.818.416-.841a15.291 15.291 0 0 0-.926-.385c-.139.285-.28.568-.419.849.283.1.593.226.929.377"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.16 0 0 13.17 6.574 138.163)"
      d="m16.83 13.111-.458.914 1.112.012.457-.914z"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'evenodd',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.16 0 0 13.17 6.575 138.066)"
      d="m16.595 1.123.436-.88c-.332-.06-.68-.11-1.044-.145l-.451.91c.369.027.725.064 1.059.115"
    />
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="matrix(13.16 0 0 13.17 6.584 138.026)"
      d="m15.384 13.097-1.111-.012-.456.913 1.11.012z"
    />
  </svg>
);

const Memo = memo(SvgModelHybrid);
export default Memo;
