import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgSeat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      transform="translate(62.5)"
      d="m371.258 462.403-3.966 7.986c-8.96 18.196-27.414 29.68-47.584 29.61H55.295c-20.17.072-38.627-11.413-47.587-29.61l-3.965-7.986a35.938 35.938 0 0 1 1.554-34.748c6.463-10.53 17.878-16.94 30.17-16.94h304.068c12.291.002 23.705 6.412 30.168 16.94a35.933 35.933 0 0 1 1.554 34.748zM267.305 187.5c-.005-4.93-3.972-8.924-8.867-8.93H116.566c-4.895.005-8.863 4-8.867 8.93V250h159.607zM89.965 392.857H36.762V187.5c.05-44.36 35.75-80.308 79.803-80.357h26.6V89.286H130.41a26.535 26.535 0 0 1-20.968-10.301 26.93 26.93 0 0 1-4.84-22.981l8.867-35.714C116.419 8.359 127.066-.011 139.277 0h96.446c12.208-.012 22.854 8.354 25.809 20.281l8.867 35.723a26.933 26.933 0 0 1-4.84 22.981c-5.043 6.501-12.777 10.301-20.969 10.301h-12.755v17.857h26.6c44.053.049 79.754 35.997 79.803 80.357v205.357h-53.202V187.5c-.017-14.786-11.916-26.77-26.6-26.786H116.562c-14.684.016-26.584 11.999-26.6 26.786zM160.9 107.144h53.203V89.287H160.9zm-53.202 285.714h159.607v-125H107.698z"
    />
  </svg>
);

const Memo = memo(SvgSeat);
export default Memo;
