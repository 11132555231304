import React from 'react';
import styles from './index.module.scss';
import { ModelContents } from '../../store';

interface ModelContentProps {
  modelContent: ModelContents;
}

const ModelContent: React.FC<ModelContentProps> = ({ modelContent }) => {
  const { image, modelName, articles } = modelContent;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={image} alt={modelName} className={styles.modelImg} />
        <div className={styles.modelTitle}>{modelName}</div>
        <ul>
          {articles.map((article, articleIndex) => (
            <li key={articleIndex} className={styles.article}>
              <a
                href={article.url}
                className={styles.link}
                target={article.target || '_self'}
              >
                <img src={article.thumbnail} className={styles.thumbnail} />
                <div className={styles.details}>
                  <div
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: article.title || '' }}
                  />
                  <span className={styles.category}>
                    {article.category}
                    {article.readTime && <>&nbsp;|&nbsp; {article.readTime}</>}
                  </span>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ModelContent;
