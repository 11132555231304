import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgPauseCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      style={{
        stroke: props.color,
        strokeWidth: props.strokeWidth || 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.fill,
        fillRule: 'nonzero',
        opacity: props.opacity,
        overflow: props.overflow as string,
      }}
    />
    <path
      d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      style={{
        stroke: props.color,
        strokeWidth: props.strokeWidth || 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.fill,
        fillRule: 'nonzero',
        opacity: props.opacity,
        overflow: props.overflow as string,
      }}
    />
    <path
      d="M14 9v6M10 9v6"
      style={{
        stroke: props.color,
        strokeWidth: props.strokeWidth || 2,
        strokeDasharray: 'none',
        strokeLinecap: 'round',
        strokeDashoffset: 0,
        strokeLinejoin: 'round',
        strokeMiterlimit: 4,
        fill: props.fill,
        fillRule: 'nonzero',
        opacity: props.opacity,
        overflow: props.overflow as string,
      }}
    />
  </svg>
);
const Memo = memo(SvgPauseCircle);
export default Memo;
