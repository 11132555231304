import * as React from 'react';
import { SVGProps } from 'react';
const SvgFinanceQuote = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={props.color}
      d="M18.5 0A18.5 18.5 0 1 0 37 18.5 18.5 18.5 0 0 0 18.5 0zm0 34.69A16.19 16.19 0 1 1 34.69 18.5 16.21 16.21 0 0 1 18.5 34.69z"
    />
    <path
      fill={props.color}
      d="M20.21 7.43v2.2a6.4 6.4 0 0 1 2.66.9 7.68 7.68 0 0 1 2 2l-2.67 1.22A3.59 3.59 0 0 0 19 12a3.89 3.89 0 0 0-2 .57 1.56 1.56 0 0 0-.76 1.43 1.58 1.58 0 0 0 .66 1.33 10.18 10.18 0 0 0 2.56 1.23l2.76 1.1a5.41 5.41 0 0 1 1.66 1 4.28 4.28 0 0 1 1.56 3.29 4.62 4.62 0 0 1-1.44 3.3 6.61 6.61 0 0 1-3.76 2v2.33h-2.45v-2.21a8 8 0 0 1-4.12-1.56 6.69 6.69 0 0 1-2.09-3.56l3.09-.53a10.27 10.27 0 0 0 .7 1.4 4.55 4.55 0 0 0 .86 1 4.67 4.67 0 0 0 2.58.66 3.92 3.92 0 0 0 2.51-.76 2.58 2.58 0 0 0 1-2 2.33 2.33 0 0 0-.86-1.79l-1.23-.76-2.09-.9a19.43 19.43 0 0 1-2.23-.93 4.77 4.77 0 0 1-1.33-.83 3.63 3.63 0 0 1-1.33-2.89 3 3 0 0 1 .33-1.43 5.33 5.33 0 0 1 .9-1.23 14.63 14.63 0 0 1 1.43-1q1.2-.43 2-.66V7.43z"
    />
  </svg>
);
const Memo = React.memo(SvgFinanceQuote);
export default Memo;
