import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgLeaf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        fill: props.color,
        fillRule: 'nonzero',
        opacity: 1,
      }}
      vectorEffect="non-scaling-stroke"
      d="M496.661 0s69.487 601.57-425.534 485.013c0 0 60.298-180.637 267.61-325.92 0 0-155.053 54.688-322.74 272.889 0 0-82.121-248.583 125.765-372.322C216.991 14.915 329.548 0 496.662 0z"
    />
  </svg>
);

const Memo = memo(SvgLeaf);
export default Memo;
