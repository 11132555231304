import * as React from 'react';
import { SVGProps } from 'react';
const SvgCar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M17.376 6.129A5.1 5.1 0 0 1 18 8.571V15.3a2.1 2.1 0 0 1-2.1 2.1h-1.8a2.1 2.1 0 0 1-2.1-2.1V15H6v.3a2.1 2.1 0 0 1-2.1 2.1H2.1A2.1 2.1 0 0 1 0 15.3V8.571a5.1 5.1 0 0 1 .624-2.442L3.966 0h10.068l3.342 6.129ZM15.9 15.6a.3.3 0 0 0 .3-.3V8.571a3.3 3.3 0 0 0-.402-1.581L12.966 1.8H5.034L2.202 6.99A3.3 3.3 0 0 0 1.8 8.571V15.3a.3.3 0 0 0 .3.3h1.8a.3.3 0 0 0 .3-.3v-2.1h9.6v2.1a.3.3 0 0 0 .3.3h1.8Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color}
      d="M4.2 9.3a1.2 1.2 0 0 0 1.2 1.2h7.2a1.2 1.2 0 0 0 1.2-1.2v-.6H4.2v.6Z"
    />
  </svg>
);
export default SvgCar;
