export const groupBy = <T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string
) =>
  array.reduce((acc, value, index, _array) => {
    (acc[predicate(value, index, _array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });

export function replaceItemAtIndex<T>(arr: T[], index: number, newValue: T) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}
