import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgPath = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        opacity: props.opacity,
        color: props.color,
      }}
      d="M11.84 5.41 6.578 1.65 1.314 5.41l-.569-.796L6.577.45l5.832 4.164z"
      fill="#1637A0"
      fillRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgPath);
export default Memo;
