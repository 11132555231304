import * as React from 'react';
import { SVGProps } from 'react';
const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 15 19"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M10.53 7.316A4.147 4.147 0 0 1 7.5 8.57a4.148 4.148 0 0 1-3.03-1.255 4.147 4.147 0 0 1-1.256-3.03c0-1.178.423-2.197 1.256-3.03A4.147 4.147 0 0 1 7.5 0c1.177 0 2.197.422 3.03 1.255a4.147 4.147 0 0 1 1.256 3.03 4.147 4.147 0 0 1-1.256 3.03ZM7.5 1.07a3.07 3.07 0 0 0-2.272.942c-.634.634-.942 1.377-.942 2.273 0 .895.308 1.639.942 2.272.634.634 1.377.942 2.272.942a3.07 3.07 0 0 0 2.273-.942c.633-.634.941-1.377.941-2.272 0-.896-.308-1.64-.941-2.273A3.07 3.07 0 0 0 7.5 1.071Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M14.818 12.726c.072.418.121.815.146 1.178.024.356.036.726.036 1.102 0 .974-.298 1.763-.887 2.344-.581.573-1.35.864-2.284.864H3.17c-.935 0-1.703-.29-2.284-.864C.298 16.768 0 15.98 0 15.006c0-.374.012-.745.036-1.101.024-.364.073-.761.146-1.18a9.6 9.6 0 0 1 .279-1.184c.116-.377.274-.749.47-1.105.202-.37.44-.692.707-.958.28-.277.622-.5 1.017-.663.395-.162.831-.244 1.299-.244.183 0 .36.078.703.31.214.145.461.31.734.49.235.156.554.302.948.434.385.13.775.195 1.16.195.386 0 .776-.066 1.16-.195.395-.132.714-.278.95-.434.275-.182.522-.347.733-.49.342-.231.52-.31.703-.31.468 0 .905.082 1.299.244.396.163.738.386 1.017.663.267.265.505.588.708.958.195.356.353.728.47 1.105.112.364.206.763.279 1.185Zm-3.002 4.417c.663 0 1.172-.186 1.554-.567.376-.374.558-.885.559-1.56 0-.353-.012-.7-.034-1.032a10.52 10.52 0 0 0-.131-1.069 8.561 8.561 0 0 0-.245-1.05 4.908 4.908 0 0 0-.382-.904 3.087 3.087 0 0 0-.519-.712 2.057 2.057 0 0 0-.674-.44 2.325 2.325 0 0 0-.828-.166c-.037.02-.102.06-.208.132-.216.147-.458.31-.74.499-.31.207-.71.394-1.188.556a4.613 4.613 0 0 1-1.48.248c-.495 0-.993-.083-1.48-.249a5.099 5.099 0 0 1-1.188-.555 97.633 97.633 0 0 1-.74-.5 2.775 2.775 0 0 0-.209-.131c-.302.008-.58.064-.827.167-.268.11-.495.258-.675.439a3.093 3.093 0 0 0-.519.711 4.919 4.919 0 0 0-.381.905c-.098.32-.18.674-.245 1.05-.066.382-.11.741-.131 1.068-.022.334-.034.68-.034 1.032 0 .676.183 1.187.558 1.561.383.381.891.567 1.554.567h8.633Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = React.memo(SvgUser);
export default Memo;
